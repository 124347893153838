// import { combineReducers } from "redux";
import {
    PUSH_ANWSER,
    GO_TO_ANWSER,
    UPDATE_ANWSER,
    RESTART_QUIZ,
    ADD_TO_BASKET,
    UPDATE_BASKET,
    ADD_NEW_TAGS,
    UPDATE_PACKAGE,
    UPDATE_DELETE_ANWSER
} from '../actions/action'

const initialState = {
    answerList: [],
    answer: {},
    selectedChoices: [],
    idxList: [],
    stepProgress: 0,
    basketList: [],
    packageTags: [],
    selectedPackage: [],
    number: 0,

};

function reducer(state = initialState, action) {
    console.log({state: initialState, action: action})

    switch (action.type) {
        case GO_TO_ANWSER:
            const index = action.index > 0 ? (action.index - 1) : 0
            initialState.selectedChoices = initialState.answerList[index].choices
            return {
                answer: action.payload,
                answerList: initialState.answerList,
                selectedChoices: initialState.answerList[index].choices,
                idxList: initialState.idxList,
                number: 0,
                stepProgress: initialState.stepProgress
            };
        case PUSH_ANWSER:
            console.log({answer: state.answer})
            console.log({initial: initialState.answerList})
            let list = initialState.answerList/*.filter(function(ele){ 
                return ele.title != state.answer.title; 
            });*/

            let test = initialState.answerList.filter(function(ele){ 
                return ele.title != state.answer.title; 
            });

            console.log({test})
            test.push(state.answer);

            console.log({list})
            initialState.answerList = test
            initialState.idxList = [];
            initialState.stepProgress = action.progress;
            return {
                answerList: test,
                selectedChoices: [],
                stepProgress: action.progress,
                number: 0
            };

        case UPDATE_ANWSER:
            let indexList = initialState.idxList;
            indexList.push(action.index);
            if (!state.number) {
                state.number = 0
            }
            return {
                answer: action.payload,
                answerList: initialState.answerList,
                selectedChoices: action.selectedChoices,
                idxList: indexList,
                number: state.number + 1,
                stepProgress: initialState.stepProgress,
            };

        case UPDATE_DELETE_ANWSER:
            let indexListD = initialState.idxList;
            indexListD = indexListD.filter(v => v !== action.index);
            initialState.idxList = indexListD;
            return {
                answer: action.payload,
                idxList: indexListD,
                number: state.number - 1,
                answerList: initialState.answerList,
                stepProgress: initialState.stepProgress
            };

        case RESTART_QUIZ:
            initialState.answerList = [];
            initialState.stepProgress = 0;
            return {
                stepProgress: 0
            };

        case ADD_TO_BASKET:
            return {
                basketList: localStorage.getItem('basket') ? JSON.parse(localStorage.getItem('basket')) : []
            };

        case UPDATE_BASKET:
            return {
                basketList: localStorage.getItem('basket') ? JSON.parse(localStorage.getItem('basket')) : []
            }

        case ADD_NEW_TAGS:
            return {
                packageTags: action.packageTags
            }

        case UPDATE_PACKAGE:
            return {
                selectedPackage: action.selectedPackage,
                packageTags: state.packageTags
            }
        default:
            return state;
    }
}

export default reducer
// export default combineReducers({});
