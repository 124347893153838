import React from 'react';
import {connect} from 'react-redux';
import reducer from '../../redux/reducers';
import {PUSH_ANWSER} from '../../redux/actions/action';
import Parse from 'parse';
import './Quiz.css';
import logoLJSM from '../../assets/navbar/logo_rounded.svg';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import MobileSelection from './mobile/MobileSelection';
import { resolveQueryParam, parseFilter, QUERYMAPPING_AMBIANCE, QUERYMAPPING_CATEGORY } from '../../utils/queryParam'
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Quiz extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            awserList: [],
            resumPicture: [],
            indexList: [],
            open: false,
            colorList: ['#6A7974', '#6A7974', '#6A7974'],
            pictureList: [],
            profil: null,
            timeSearch: true,
            listTag: [],
            algoList: [],
            categoryList: [],
            finalSelectionProfile: [],
            openError: false,
            width: 0
        };
        window.addEventListener('resize', this.update);
    }

    update = () => {
        this.setState({
            width: window.innerWidth
        });
    };

    componentDidMount = () => {
        this.update();
    };

    // componentWillReceiveProps(nextProps) {
    // }


    handleClick() {
        this.setState({open: true});
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false, openError: false});
    };

    onQuizAnswer = (item, index) => {
        let awsList = this.props.selectedChoices ? this.props.selectedChoices : [];
        let idxList = this.props.idxList ? this.props.idxList : [];
        if (idxList.includes(index)) {
            awsList = awsList.filter(v => v.type !== item.get('Type'));
            let answerStep = {title: item.get('Step'), choices: awsList};
            this.props.dispatch({type: 'UPDATE_DELETE_ANWSER', index: index, payload: answerStep});
            this.selectedImgStyle(index);
            return;
        }
        if (this.props.number >= 2) {
            return;
        }

    
        awsList.push({
            type: item.get('Type'),
            picture: item.get('Picture'),
            tag: item.get('Tag'),
            step: item.get('Step'),
            tags: item.get('Tags'),
            category: item.get('Category').id
        });
        let answerStep = {title: item.get('Step'), choices: awsList};
        this.selectedImgStyle(index);
        this.props.dispatch({type: 'UPDATE_ANWSER', payload: answerStep, selectedChoices: awsList, index: index});
    };

    selectedImgStyle(index) {
        if (this.props.idxList) {
            if (this.props.idxList.includes(index)) {
                return 'border-select';
            } else {

            }
        }
    }

    buildURL = () => {
        //this.updateProfiling(9);
        const product_cat = this.props.answerList[0].choices.map (c => c.type.toLowerCase())
        const ambiances = this.props.answerList[1].choices.map (c => c.type.toLowerCase())
       // ?filters=product_cat[209]|ambiances[206-201]
       const filters = {
        product_cat: product_cat.map(pc => QUERYMAPPING_CATEGORY[pc]).filter(Boolean).join('-'),
        ambiances: ambiances.map(a => QUERYMAPPING_AMBIANCE[a]).filter(Boolean).join('-')
       }
       console.log({
        product_cat: product_cat,
        ambiances: ambiances
       })
       console.log({filters})
       const BASE_URL = `https://emericlegros-shopljsm.pf7.wpserveur.net`
       const ENDPOINT = `votre-selection-de-produits-deco`
       return `${BASE_URL}/${ENDPOINT}/?filters=product_cat[${filters.product_cat}]|ambiances[${filters.ambiances}]&from=ljsm`
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (event.target['email'].value.length <= 0) {
            this.setState({openError: true});
            return;
        }
        this.setState({open: true});
        var color2 = ['white', 'white'];
        if (this.state.colorList[1]) {
            color2 = this.state.colorList[1];
        }
        console.log({url: this.buildURL()})
        const response = Parse.Cloud.run('emailprofiling', {
            email: event.target['email'].value,
            picture1: this.state.pictureList[0],
            picture2: this.state.pictureList[1],
            picture3: this.state.pictureList[2],
            picture4: this.state.pictureList[6],
            picture5: this.state.pictureList[5],
            color1: this.state.colorList[0][0],
            color2: this.state.colorList[0][1],
            color3: color2[0],
            color4: color2[1],
            selectionURL: this.buildURL(),
            finalSelectionProfile: this.state.finalSelectionProfile
        });
        console.log({response: response, color1: this.state.colorList[0][0],
            color2: this.state.colorList[0][1],
            color3: color2[0],
            color4: color2[1],})
    };
    showProfileSelected = () => {
        const {finalSelectionProfile} = this.state;
        console.log({profil: this.state})
        return (<div style={{paddingLeft: '15%', paddingRight: '15%'}}>
                {finalSelectionProfile.map((profileList, key) => <div key={key}>
                    <h2 className="title-quizz">{profileList.name}</h2>
                    {/* <h2>{profileList.category}: {profileList.name}</h2> */}
                    <p>{profileList.text}</p>
                </div>)}
            </div>);
    };

    diff = (arr1, arr2) => {
        var ret = [];
        for (var i in arr1) {
            if (arr2.indexOf(arr1[i]) > -1) {
                ret.push(arr1[i]);
            }
        }
        return ret;
    };

    getProfile = async (tabSortCategory) => {
        let Profile = Parse.Object.extend('Profile');
        const query = new Parse.Query(Profile);
        const results = await query.find();
        let bestScore = 0;
        let score = 0;
        let finalSelectionProfile = [];

        tabSortCategory.forEach(answer => {
            let selectedProfile = [];
            bestScore = 0;
            results.forEach(profile => {
                score = this.diff(answer.tagsAnswer, profile.get('Tags')).length;
                if (score >= bestScore) {
                    bestScore = score;
                    selectedProfile = {
                        category: answer.category,
                        name: profile.get('Name'),
                        text: profile.get('Description'),
                        tags: profile.get('Tags')
                    };
                }
            });
            finalSelectionProfile.push(selectedProfile);
        });
        this.setState({finalSelectionProfile: finalSelectionProfile});
        this.props.getPrimaryProfile(finalSelectionProfile);
    };

    formatTabSortCategory = (tabSortCategory) => {
        var newTab = tabSortCategory.map(tab => {
            var tabvalue = [];
            tab.tagsAnswer.forEach(tab => {
                tabvalue = tabvalue.concat(tab);
            });
            return {category: tab.category, tagsAnswer: tabvalue};
        });
        return newTab;
    };

    getCategory = async (listAnswer) => {
        let tabTagsSortByCategory = [];
        let tabSortCategory = [];
        let Category = Parse.Object.extend('category');
        console.log({PARSE_Category: Category})
        const query = new Parse.Query(Category);
        const results = await query.find();

        console.log({categoryList: results})
        this.setState({categoryList: results});
        results.forEach(category => {
            tabTagsSortByCategory = [];
            listAnswer.forEach(element => {
                if (category.id === element.category) {
                    tabTagsSortByCategory.push(element.tagsList);
                }
            });
            tabSortCategory.push({category: category.get('name'), tagsAnswer: tabTagsSortByCategory});
        });
        tabSortCategory = this.formatTabSortCategory(tabSortCategory);
        this.getProfile(tabSortCategory);
    };

    async searchProfil(list) {

        if (this.state.timeSearch) {
            await this.getCategory(list);
            this.setState({timeSearch: false});
        } else {
        }
    }


    lastStep = () => {

        console.log({answers: this.props.answerList})
       
        let pictureList = [];
        let algoList = [];
        let colorList = [];
        let tagsList = [];
        let countColor = 0;

        this.props.answerList.forEach(element => {
            if (element.choices.length === 2) {
                if (element.choices[1].step === 2) {
                    colorList.push(element.choices[1].tags);
                    countColor = countColor + 1;
                }
            }
            if (element.choices[0].step === 2) {
                colorList.push(element.choices[0].tags);
                countColor = countColor + 1;
            }
            if (element.choices.length > 1) {
                tagsList.push(element.choices[1].tag);
                algoList.push({category: element.choices[1].category, tagsList: element.choices[1].tags});
            }
            tagsList.push(element.choices[0].tag);
            pictureList.push(element.choices[0].picture._url);
            algoList.push({category: element.choices[0].category, tagsList: element.choices[0].tags});
        });
        this.searchProfil(algoList);

        console.log({colorList: colorList, pictureList: pictureList})
        if (this.state.width < 800) {
            
            return (<div className="quiz-mobile">
                    <h1>{this.props.step.title}</h1>
                    <p>{this.props.step.description}</p>
                    <MobileSelection colorList={colorList} pictureList={pictureList}/>
                    <div>
                        {this.showProfileSelected()}
                    </div>
                    <form onSubmit={this.handleSubmit} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '26%',
                        marginLeft: '26%'
                    }}>
                        <input className="email-input" name="email" type="email" placeholder="email"></input>
                        <button style={{
                            marginTop: '1rem',
                            marginBottom: '2rem'
                        }} onClick={() => this.setState({
                            colorList: colorList,
                            pictureList: pictureList,
                            algoList: algoList
                        })} className="selection-btn color-btn" type="submit">Envoyer mon profil déco par mail
                        </button>
                    </form>
                    <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity="success">
                            Votre message a bien été envoyé.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={this.state.openError} autoHideDuration={6000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity="error">
                            Email non valide.
                        </Alert>
                    </Snackbar>
                </div>);
        }
        return (<div className="quiz">
                <h1>{this.props.step.title}</h1>
                <h2 className="subtitle-quiz">{this.props.step.description}</h2>
                <div style={{display: 'flex'}}>
                    <div style={{width: '50%', margin: '2px'}}>
                        <div>
                            <img style={{
                                height: '400px',
                                width: '100%',
                                margin: '1px',
                                objectFit: 'cover',
                                borderRadius: '7px'
                            }} src={pictureList[0]}/>
                        </div>
                        <div style={{margin: '2px'}}>
                            {colorList.length >= 2 ?
                                <div style={{display: 'flex', justifyContent: 'space-between', margin: '2px'}}>
                                    <div style={{
                                        height: '75px',
                                        width: '29%',
                                        marginRight: '2px',
                                        backgroundColor: `${colorList[0][0]}`,
                                        borderRadius: '7px'
                                    }}/>
                                    <div style={{
                                        height: '75px',
                                        width: '29%',
                                        marginRight: '2px',
                                        backgroundColor: `${colorList[0][1]}`,
                                        borderRadius: '7px'
                                    }}/>
                                    <div style={{
                                        height: '75px',
                                        width: '29%',
                                        marginRight: '2px',
                                        backgroundColor: `${colorList[1][0] ? colorList[1][0] : 'white'}`,
                                        borderRadius: '7px'
                                    }}/>
                                    <div style={{
                                        height: '75px',
                                        width: '29%',
                                        backgroundColor: `${colorList[1][1] ? colorList[1][1] : 'white'}`,
                                        borderRadius: '7px'
                                    }}/>
                                </div> : <div style={{display: 'flex', justifyContent: 'space-between', margin: '2px'}}>
                                    <div style={{
                                        height: '75px',
                                        width: '30%',
                                        backgroundColor: `${colorList[0][0]}`,
                                        borderRadius: '7px',
                                        marginRight: '2px'
                                    }}/>
                                    <div style={{
                                        height: '75px',
                                        width: '30%',
                                        backgroundColor: `${colorList[0][1]}`,
                                        borderRadius: '7px',
                                        marginRight: '2px'
                                    }}/>
                                    <div style={{
                                        height: '75px',
                                        width: '30%',
                                        backgroundColor: 'white',
                                        borderRadius: '7px'
                                    }}/>
                                    <div style={{
                                        height: '75px',
                                        width: '30%',
                                        backgroundColor: 'white',
                                        borderRadius: '7px'
                                    }}/>
                                </div>}
                        </div>
                    </div>
                    <div style={{width: '50%'}}>
                        <div style={{width: '100%', display: 'flex', margin: '2px'}}>
                            <img style={{
                                height: '200px',
                                width: '50%',
                                margin: '1px',
                                objectFit: 'cover',
                                borderRadius: '7px'
                            }} src={pictureList[1]}/>
                            <img style={{
                                height: '200px',
                                width: '50%',
                                margin: '1px',
                                objectFit: 'cover',
                                borderRadius: '7px'
                            }} src={pictureList[2]}/>
                        </div>
                        <div style={{width: '100%', display: 'flex', margin: '2px'}}>
                            <div style={{width: '40%', display: 'flex', flexDirection: 'column'}}>
                                <img style={{
                                    height: '150px',
                                    objectFit: 'cover',
                                    borderRadius: '7px'
                                }} src={pictureList[6]}/>
                                <img style={{height: '150px', objectFit: 'cover', borderRadius: '7px'}} src={logoLJSM}/>
                            </div>
                            <div style={{width: '60%'}}>
                                <img style={{
                                    height: '300px',
                                    objectFit: 'cover',
                                    width: '100%',
                                    borderRadius: '7px'
                                }} src={pictureList[5]}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {this.showProfileSelected()}
                </div>
                <form onSubmit={this.handleSubmit} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '26%',
                    marginLeft: '26%'
                }}>
                    <input className="email-input" name="email" type="email" placeholder="email"/>
                    <button style={{marginTop: '1rem'}} onClick={() => this.setState({
                        colorList: colorList,
                        pictureList: pictureList,
                        algoList: algoList
                    })} className="selection-btn color-btn" type="submit">Envoyer mon profil déco par mail
                    </button>
                </form>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="success">
                        Votre message a bien été envoyé.
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openError} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="error">
                        Email non valide.
                    </Alert>
                </Snackbar>
            </div>);
    };

    render() {
        return (<>
                {this.props.step.nbr > 7 ? this.lastStep() : <div className="quiz">
                    <h1>{this.props.step.title}</h1>
                    <h2 className="subtitle-quiz">{this.props.step.description}</h2>
                    <div className="image-list">
                        {this.props.choices.map((choice, index) =>
                            <img className={`quiz-img ${this.selectedImgStyle(index)}`} key={index} onClick={() => this.onQuizAnswer(choice, index)} src={choice.get('Picture')._url}/>)}
                    </div>
                </div>}
            </>);
    }
}

const mapStateToProps = (state) => {
    return {
        answer: state.answer,
        selectedChoices: state.selectedChoices,
        answerList: state.answerList,
        idxList: state.idxList,
        number: state.number
    };
};

export default connect(mapStateToProps)(Quiz);
