import React from 'react';
import addBasketWhite from '../../assets/shop/basket_opacity.svg'
import './Shop.css';
import ShopCard from '../../component/shop-card/ShopCard';
import Parse from 'parse';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import basketWhite from '../../assets/home/add_basket_white.svg';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import Selection from '../../component/selection/Selection'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Basket from '../../component/basket/Basket';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css"

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

class Shop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            profils: [],
            time: 0,
            drawer: false,
            width: 0,
            filter: [],
            open: false,
            name: "",
            value: { min: 0, max: 500 },
            basketUpdated: false,

            shouldScrollToItem: false,
        }
        window.addEventListener("resize", this.update);
    }

    async componentDidMount() {
        this.getProduct();
        this.getProfile();
        this.update();
    }

    componentDidUpdate() {
        if (this.state.shouldScrollToItem) {
          const itemId = localStorage.getItem("shopItemSelected", undefined);
          localStorage.removeItem("shopItemSelected");

          let elementToScrollTo = document.getElementById(`scroll-shop-item-${itemId}`);
          if (!elementToScrollTo) {
            elementToScrollTo = document.getElementById("scroll-top");
          }

          elementToScrollTo.scrollIntoView(true);

          this.setState({shouldScrollToItem: false});
        }
    }

    async getProduct() {
        const { value } = this.state;
        let Product = Parse.Object.extend("Product");
        const query = new Parse.Query(Product);
        query.equalTo("Pack", false);
        query.equalTo("Validate", true);
        query.notEqualTo("IsGiftCard", true);
        query.greaterThanOrEqualTo("PriceHT", value.min * 80);
        query.lessThanOrEqualTo("PriceHT", value.max * 80);
        query.limit(1000);
        const results = await query.find();
        this.setState({ items: results, shouldScrollToItem: true });
    }

    async updateProduct(filter) {
        const { value } = this.state;
        let Profile = Parse.Object.extend("Product");
        const query = new Parse.Query(Profile);
        query.equalTo("Pack", false);
        query.equalTo("Validate", true);
        query.notEqualTo("IsGiftCard", true);
        query.greaterThan("PriceHT", value.min * 80);
        query.lessThanOrEqualTo("PriceHT", value.max * 80);
        if (filter.length > 0) {
            query.containedIn("Tags", this.retrieveTags(filter));
        }
        query.limit(1000);
        const results = await query.find();
        this.setState({ items: results })
    }

    async getProfile() {
        let Profile = Parse.Object.extend("Profile");
        const query = new Parse.Query(Profile);
        query.equalTo("Validate", true);
        const results = await query.find();
        this.setState({ profils: results })
    }

    retrieveTags = (packageTags) => {
        let tags = []
        packageTags.forEach((element, index) => {
            if (index === 0) {
                tags = element.tags;
            } else {
                tags = tags.concat(element.tags);
            }
        });
        return tags
    }

    update = () => {
        this.setState({
            width: window.innerWidth
        });
    };

    addTagsToFilter = (item) => {
        const { filter } = this.state;
        let filterList = filter;
        filterList.push(item);
        this.updateProduct(filterList);
        this.setState({ filter: filterList });
        // this.updateProduct(filterList);
    }

    deleteTagsFromFilter = (itemName) => {
        const { filter } = this.state;
        let filterList = filter;
        filterList = filterList.filter(v => v.name !== itemName)
        this.setState({ filter: filterList });
        this.updateProduct(filterList);
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };

    handleClick = () => {
        this.setState({ open: true });
    };

    addItemToBasket = (name) => {
        this.setState({ name: name });
        this.handleClick();
    }

    handleChange = (event, item) => {
        if (event.target.checked == true) {
            // add
            this.addTagsToFilter({ name: event.target.name, tags: item })
        } else {
            this.deleteTagsFromFilter(event.target.name)
            // delete
        }
    }

    toggleDrawer = (anchor, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ anchor: open });
    };

    list = (anchor) => (
        <div
            role="presentation"
            onClick={this.toggleDrawer(anchor, false)}
            onKeyDown={this.toggleDrawer(anchor, false)}
            className="basket-drawer"
        >
            <h2>Votre panier</h2>
            <div style={{ marginTop: "14rem" }}>
                <img src={addBasketWhite} />
                <p>Votre panier est vide</p>
            </div>
        </div>
    );

    onChangePriceRange = (value) => {
        const { filter, time } = this.state
        this.setState({ value });
        if (time) {
            clearTimeout(time);
        }
        this.setState({
            time: setTimeout(function () {
                this.updateProduct(filter)
            }.bind(this), 1000)
        })
    }
    onStartQuiz = () => {
        this.props.dispatch({ type: 'IN_PROFILING' })
    }

    render() {
        const { profils, value } = this.state;
        let anchor = 'right'
        let showSelection = false;
        if (localStorage.getItem('mySelection') === null) {
            showSelection = false
        } else {
            showSelection = true
        }
        let widthSize = this.state.width < 1100 ? "100%" : "78%"

        return (
            <div style={{ display: "flex", paddingLeft: "10%", paddingRight: "5%", paddingTop: "3rem", position: "relative" }}>
                <div style={{ width: widthSize }}>
                    {/* selection */}
                    <div className="selection">
                        <h2>Votre sélection</h2>
                        {showSelection
                            ? <p style={{ width: "90%" }}>Découvrez les articles correspondant à votre profil déco ci-dessous. Vous pouvez compléter cette sélection avec les articles de la boutique.</p>
                            : <p style={{ width: "90%" }}>Vous ne connaissez pas votre profil déco ? Répondez au questionnaire du profil déco et découvrez ci-dessous dans votre Sélection les articles déco qui vous ressemblent.</p>}
                        {
                            showSelection ? <Selection basketUpdated={this.state.basketUpdated} onItemUpdated={() => this.setState({ basketUpdated: false })}></Selection> :
                                (<>
                                    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                        <Link to={'/profiling'}><button onClick={this.onStartQuiz} className="selection-btn">Faire mon profil déco</button></Link>
                                    </div>
                                </>)
                        }
                    </div>
                    {/* selection */}

                    {/* shop */}
                    <div className="shop">
                        <h2>La boutique</h2>
                        <p>Découvrez tous les articles de la boutique LJSM. <br /> Utilisez les filtres pour voir tous les styles. <br /> Vous pouvez alors compléter votre sélection issue du profiling, ou composez vous-même votre pack déco.</p>
                        {/* <h2>witdh: {this.state.width}</h2> */}
                        <div className="shop-list">
                            {/* filter */}
                            <div className="filter">
                                <p style={{ marginBottom: "2rem" }}>FILTRER PAR</p>
                                <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                                    <InputRange
                                        maxValue={500}
                                        minValue={0}
                                        value={this.state.value}
                                        formatLabel={value => `${value} €`}
                                        onChange={(value) => this.onChangePriceRange(value)} /></div>
                                <div style={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}>
                                    {
                                        profils.map((item, key) =>
                                            <label key={key} className="filter-label">
                                                <Checkbox onChange={(e) => this.handleChange(e, item.get("Tags"))} name={item.get("Name")} />
                                                {item.get("Name")}
                                            </label>
                                        )
                                    }
                                </div>
                            </div>

                            {/* filter */}
                            {/* shop-card */}
                            {
                                this.state.items.map((item, key) =>
                                    <ShopCard key={key} item={item} click={this.addItemToBasket} />
                                )
                            }
                            {/* shop-card */}
                        </div>
                    </div>
                    {/* shop */}
                </div>

                {/* basket */}
                <Basket onBasketUpdate={() => this.setState({ basketUpdated: true })} width={this.state.width} />
                {/* basket */}
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="success">
                        {this.state.name && this.state.name} a bien été ajouté à votre panier
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        inProfiling: state.inProfiling
    };
}

export default connect(mapStateToProps)(Shop);
