import React from 'react';
import { connect } from 'react-redux';
import btnSave from '../../assets/profiling/btn_save.svg'
import btnShare from '../../assets/profiling/btn_share.svg'
import Chambre from '../../assets/profiling/CHAMBRE.jpg'
import basketWhite from '../../assets/home/add_basket_white.svg';
import addBasketWhite from '../../assets/shop/basket_opacity.svg'
import Drawer from '@material-ui/core/Drawer';
import './Profil.css';
import btnBasket from '../../assets/home/btn_profile.svg';
import { BottomNavigationAction } from '@material-ui/core';
import * as _ from 'lodash'
class Profil extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    componentWillReceiveProps(nextProps) {
    }

    stepTitle = (step) => {
        switch (step) {
            case 1:
                return "Votre style";
            case 2:
                return "Vos teintes";
            case 3:
                return "Votre style de mobilier";
            case 4:
                return "Mélange couleurs";
            case 5:
                return "Epuré / Acc";
            case 6:
                return "Ambiance";
            case 7:
                return "Photo";
            default:
                break;
        }
    }

    toggleDrawer = (anchor, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ anchor: open });
    };

    list = (anchor, answerList) => (
        <div
            role="presentation"
            onClick={this.toggleDrawer(anchor, false)}
            onKeyDown={this.toggleDrawer(anchor, false)}
            className="basket-drawer"
        >
            <h2>Votre profil</h2>
            <div className="scrol-list-profil-drawer">
                {answerList ?
                    _.sortBy(answerList, 'title' )
                    .map((answer, key) =>
                        <div key={key}>
                            <div className="selected-point">
                                <span>{this.stepTitle(answer.title)}</span>
                            </div>
                            {
                                answer.choices.map((choice, key) =>
                                    <div key={key} className="them-selected-point" style={{ backgroundImage: `url(${choice.picture._url})`, backgroundSize: "cover" }}>
                                        <span>{choice.type}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                    : <></>}
            </div>
        </div>
    );

    render() {
        let anchor = 'right'

        console.log(_.sortBy( this.props.answerList, 'title' ));
        return (
            <>
                <div hidden={this.props.width < 1100} className="profil">
                    <h2>Votre profil</h2>
                    <div className="scrol-list-profil">
                        {this.props.answerList ?
                            _.sortBy( this.props.answerList, 'title' )
                            .map((answer, key) =>
                                <div key={key}>
                                    <div className="selected-point">
                                        <span>{this.stepTitle(answer.title)}</span>
                                    </div>
                                    {
                                        answer.choices.map((choice, key) =>
                                            <div key={key} className="them-selected-point" style={{ backgroundImage: `url(${choice.picture._url})`, backgroundSize: "cover" }}>
                                                <span>{choice.type}</span>
                                            </div>
                                        )
                                    }
                                </div>
                            )
                            : <></>}
                    </div>
                </div>

                <div hidden={this.props.width > 1100}>
                    <React.Fragment key={anchor}>
                        <div style={{ height: "40px", position: "fixed", top: "200px", right: "0px" }} onClick={this.toggleDrawer(anchor, true)}>
                            <img style={{ height: "67px", width: "35px", marginTop: "10px" }} src={btnBasket} />
                        </div>
                        <Drawer anchor={anchor} open={this.state.anchor} onClose={this.toggleDrawer(anchor, false)}>
                            {this.list(anchor, this.props.answerList)}
                        </Drawer>
                    </React.Fragment>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        answerList: state.answerList,
        answer: state.answer,
        selectedChoices: state.selectedChoices
    };
}

export default connect(mapStateToProps)(Profil);
