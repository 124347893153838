import React from 'react';
import Parse from 'parse';
import Chip from '../chip/Chip'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


class ChipList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profiles: [],
            // packageTags: []
        }
    }

    async componentDidMount() {
        let Profile = Parse.Object.extend("Profile");
        const query = new Parse.Query(Profile);
        query.equalTo("Validate", true);
        const results = await query.find();
        this.getPackage();
        this.setState({ profiles: results })
    }

    componentWillReceiveProps(nextProps) {
        // this.setState({packageTags: nextProps.packageTags})
    }

    async getPackage() {
        let Product = Parse.Object.extend("Product");
        const query = new Parse.Query(Product);
        query.equalTo("Pack", true);
        query.equalTo("Validate", true);
        const results = await query.find();
        this.props.dispatch({type: "UPDATE_PACKAGE", selectedPackage: results})
    }

    async getSelectedPackage(packageTags) {
        let Profile = Parse.Object.extend("Product");
        const query = new Parse.Query(Profile);
        query.equalTo("Pack", true);
        query.equalTo("Validate", true);
        if (packageTags.length > 0) {
            query.containedIn("Tags", this.retrieveTags(packageTags));
        }
        const results = await query.find();
        this.setState({ packs: results })
        this.props.dispatch({type: "UPDATE_PACKAGE", selectedPackage: results})
    }

    retrieveTags = (packageTags) => {
        let tags = []
        packageTags.forEach((element, index) => {
            if (index === 0) {
                tags = element.tags;
            } else {
                tags = tags.concat(element.tags);
            }
        });
        return tags
    }

    isClickChip = (item) => {
        this.getSelectedPackage(item);
    }

    render() {
        return (
            <div className="select-type">
                {
                    this.state.profiles.map((profile, key) =>
                        <Chip key={key} name={profile.get("Name")} getTags={this.getTags} tags={profile.get("Tags")} click={this.isClickChip} selected={true}></Chip>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        packageTags: state.packageTags,
        selectedPackage: state.selectedPackage
    };
}

export default connect(mapStateToProps)(ChipList);
