import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import bgProfiling from '../../assets/profiling/bg_profiling.png'
import './ProfilingPage.css';
import Quiz from '../../component/quiz/Quiz';
import Parse from 'parse';
import Profil from '../../component/profil/Profil';
import { resolveQueryParam, parseFilter, QUERYMAPPING_AMBIANCE, QUERYMAPPING_CATEGORY } from '../../utils/queryParam'

/*
Parse.serverURL = 'https://parseapi.back4app.com/';
Parse.initialize("QAg12WMZ0ZIORqF7iAIjCd2GliwizGVCU5yOrAvZ", "zTBq1RkEHsZ94h0js5rysCIIGUt1n3j7FKFI3dRY");

require('../../cloud/profiling')
*/
class ProfilingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: {
                nbr: 1,
                title: "Quel style préférez-vous ?",
                description: "Découvrez votre profil déco en réalisant ce questionnaire. 1 choix possible pour cette 1ère question",
            },
            choices: [],
            selectedChoice: [],
            click: 0,
            width: 0,

            shouldScrollTop: false,
        }
        window.addEventListener("resize", this.update);
    }

    async getChoices(stepNbr) {
        let Quiz = Parse.Object.extend("Quiz");
        const query = new Parse.Query(Quiz);
        query.equalTo("Step", stepNbr);
        const results = await query.find();
        console.log({getChoicesQuiz: results})
        this.setState({ choices: results, shouldScrollTop: true })
    }

    async readChoices(stepNbr) {
        let Quiz = Parse.Object.extend("Quiz");
        const query = new Parse.Query(Quiz);
        query.equalTo("Step", stepNbr);
        return query.find();
    }

    update = () => {
        this.setState({
            width: window.innerWidth
        });
    };

    componentDidMount() {

        console.log(this.props)
        /*const query = resolveQueryParam(this.props, ["filters"])
        console.log(query)

        const filter = parseFilter(query.filters)
        console.log(filter)*/

        this.readChoices(1).then( result => console.log({choices: result}))
       /* this.props.answerList = [
            {title: 1, }
        ]*/

        this.getChoices(1);
        this.props.dispatch({ type: 'RESTART_QUIZ' })
        this.update();

    }

    componentDidUpdate() {
        if (this.state.shouldScrollTop) {
            document.getElementById("scroll-top").scrollIntoView(true);
            this.setState({shouldScrollTop: false});
        }
    }

    onRestart = () => {
        this.setState({
            step: {
                nbr: 1,
                title: "Quel style préférez-vous ?",
                description: "Découvrez votre profil déco en réalisant ce questionnaire. 1 choix possible pour cette 1ère question",
            }, choices: [],
            selectedChoice: []
        });
        this.getChoices(1);
        this.props.dispatch({ type: 'RESTART_QUIZ' })
    }

    onCreateProfilSelection = (primaryProfile) => {
        const colorsStep = this.props.answerList[1];

        const tags = primaryProfile[0].tags;
        const colors = colorsStep.choices.reduce((acc, value) => acc.concat(value.tags), []);

        const listTags = {
            tags,
            colors,
        };

        // let listTags = [];
        // this.props.answerList.forEach(answer => {
        //     for (let index = 0; index < answer.choices.length; index++) {
        //         const choice = answer.choices[index];
        //         listTags.push(choice.tag);
        //     }
        // });
        // localStorage.setItem('mySelection', JSON.stringify(listTags));
        localStorage.setItem('mySelection', JSON.stringify(listTags));
    };

    onPreviousStep = () => {
        let stepNbr = this.state.step.nbr > 1 ? (this.state.step.nbr - 1) : 1
        this.updateWithStep(stepNbr)
//PREV_ANWSER
this.props.dispatch({ type: 'GO_TO_ANWSER', index: stepNbr })
        /*console.log(stepNbr)
        console.log({props: this.props})
        console.log({answers: this.props.answerList})
        console.log({selected: this.props.answerList[stepNbr - 1]})
        let answerStep = this.props.answerList[stepNbr - 1] //{title: item.get('Step'), choices: awsList};
        let choices = answerStep.choices || []
        for (let i = 0, len = choices.length; i < len; ++i)
            this.props.dispatch({type: 'UPDATE_ANWSER', payload: answerStep, selectedChoices: answerStep.choices, index: i});*/
    }

    onNextStep = () => {
        const { click } = this.state

        if (this.props.number <= 0 || !this.props.number) {
            alert("veuillez sélectionner au moins une réponse");
            return
        }
        let stepNbr = this.state.step.nbr + 1;
        let progress = stepNbr * 12.5

        switch (stepNbr) {
            case 1:
                this.setState({ step: {
                    nbr: stepNbr,
                    title: "Quel style préférez-vous ?",
                    description: "Découvrez votre profil déco en réalisant ce questionnaire. 1 choix possible pour cette 1ère question",
                } })
                break;
            case 2:
                this.setState({ step: { nbr: stepNbr, title: "Quelles teintes préférez-vous ?", description: "1 ou 2 choix possibles" } });
                break;
            case 3:
                this.setState({ step: { nbr: stepNbr, title: "Quelle est la table basse que vous aimeriez avoir chez vous ?", description: "1 ou 2 choix possibles" } });
                break;
            case 4:
                this.setState({ step: { nbr: stepNbr, title: "Etes-vous plus pour l’uniformité (tons sur tons) ou le mélange des couleurs ?", description: "1 ou 2 choix possibles" } });
                break;
            case 5:
                this.setState({ step: { nbr: stepNbr, title: "Dans votre intérieur, préférez-vous l’accumulation des objets de décoration ou les ambiances plus épurées ?", description: "1 ou 2 choix possibles" } });
                break;
            case 6:
                this.setState({ step: { nbr: stepNbr, title: "Quel(s) style(s) d’accessoires aimeriez-vous avoir chez vous ?", description: "1 ou 2 choix possibles" } });
                break;
            case 7:
                this.setState({ step: { nbr: stepNbr, title: "Quelle est votre préférence parmi tous ces fauteuils ?", description: "1 ou 2 choix possibles" } });
                break;
            case 8:
                this.setState({ step: { nbr: stepNbr, title: "Récapitulatif de vos préférences", description: "A la suite de vos réponses, nous avons pu établir une planche tendance qui correspond à vos attentes et vos goûts." } });
                break;
            default:
                break;
        }
        this.getChoices(stepNbr);
        this.props.dispatch({ type: 'PUSH_ANWSER', progress: progress })
        this.updateProfiling(stepNbr);

        console.log(this.state)
        console.log({props: this.props})
    }

    updateWithStep = (stepNbr) => {
        switch (stepNbr) {
            case 1:
                this.setState({ step: {
                    nbr: stepNbr,
                    title: "Quel style préférez-vous ?",
                    description: "Découvrez votre profil déco en réalisant ce questionnaire. 1 choix possible pour cette 1ère question",
                } })
                break;
            case 2:
                this.setState({ step: { nbr: stepNbr, title: "Quelles teintes préférez-vous ?", description: "1 ou 2 choix possibles" } });
                break;
            case 3:
                this.setState({ step: { nbr: stepNbr, title: "Quelle est la table basse que vous aimeriez avoir chez vous ?", description: "1 ou 2 choix possibles" } });
                break;
            case 4:
                this.setState({ step: { nbr: stepNbr, title: "Etes-vous plus pour l’uniformité (tons sur tons) ou le mélange des couleurs ?", description: "1 ou 2 choix possibles" } });
                break;
            case 5:
                this.setState({ step: { nbr: stepNbr, title: "Dans votre intérieur, préférez-vous l’accumulation des objets de décoration ou les ambiances plus épurées ?", description: "1 ou 2 choix possibles" } });
                break;
            case 6:
                this.setState({ step: { nbr: stepNbr, title: "Quel(s) style(s) d’accessoires aimeriez-vous avoir chez vous ?", description: "1 ou 2 choix possibles" } });
                break;
            case 7:
                this.setState({ step: { nbr: stepNbr, title: "Quelle est votre préférence parmi tous ces fauteuils ?", description: "1 ou 2 choix possibles" } });
                break;
            case 8:
                this.setState({ step: { nbr: stepNbr, title: "Récapitulatif de vos préférences", description: "A la suite de vos réponses, nous avons pu établir une planche tendance qui correspond à vos attentes et vos goûts." } });
                break;
            default:
                break;
        }
        this.getChoices(stepNbr);
        this.updateProfiling(stepNbr);

        console.log(this.state)
        console.log({props: this.props})
    }

    createProfilingBase = (email, stepNbr) => {
        const Profiling = Parse.Object.extend('Profiling');
        const profiling = new Profiling();

        profiling.set('User', email);
        profiling.set('Step', stepNbr);

        profiling.save()
            .then(
                (profiling) => localStorage.setItem('ProfileId', profiling.id),
                (error) => undefined,
            );

        /*var Profiling = Parse.Object.extend("Profiling");
        var profiling = new Profiling();

        profiling.set("User", user.email);
        profiling.set("Step", user.step);

        profiling.save()
            .then((profiling) => {
                // The object was saved successfully.
                console.log("PROFILE ID CREATED", profiling.id);
                localStorage.setItem('ProfileId', profiling.id);
                //mettre une petite snack bar
            }, (error) => {
                // The save failed.
                console.log("PROFILE NOT CREATED", error);
                // error is a Parse.Error with an error code and message.
            });*/
    }

    updateProfilingBase = (profileId, email, stepNbr) => {
        const Profiling = Parse.Object.extend('Profiling');
        const query = new Parse.Query(Profiling).get(profileId)
            .then((profiling) => {
                profiling.set('User', email);
                profiling.set('Step', stepNbr);

                return profiling.save()
                    .then((profiling) => undefined, (error) => undefined);
            });

        /*// Create the object.
        var Profiling = Parse.Object.extend("Profiling");
        var query = new Parse.Query(Profiling);
        var profiling = new Profiling();

        profiling.set("User", user.email);
        profiling.set("Step", user.step);
        profiling.set("objectId", user.id);

        profiling.save().then((profiling) => {
            // Now let's update it with some new data. In this case, only cheatMode and score
            // will get sent to the cloud. playerName hasn't changed.
            profiling.set("User", user.email);
            profiling.set("Step", user.step);
            return profiling.save();
        });*/
    }

    updateProfiling = (stepNbr) => {
        const user = JSON.parse(localStorage.getItem('user')) || {};

        if (stepNbr === 2) {
            return this.createProfilingBase(user.email, stepNbr);
        } else {
            const profileId = localStorage.getItem('ProfileId');
            return this.updateProfilingBase(profileId, user.email, stepNbr);
        }

        /*let user = {};
        let profileId = "";
        let retrievedDataUser = localStorage.getItem('user');
        let retrievedDataIdProfiling = localStorage.getItem('ProfileId');
        if (retrievedDataUser === null) {
            retrievedDataUser = "{}";
        }
        user = JSON.parse(retrievedDataUser);
        profileId = retrievedDataIdProfiling;
        if (retrievedDataIdProfiling === null) {
            retrievedDataIdProfiling = "";
            this.createProfilingBase({ email: user.email, step: stepNbr })
            return;
        }
        this.updateProfilingBase({ email: user.email, id: profileId, step: stepNbr })*/
    }

    handleSeeSelectionClick = () => {
        //this.updateProfiling(9);
        const product_cat = this.props.answerList[0].choices.map (c => c.type.toLowerCase())
        const ambiances = this.props.answerList[1].choices.map (c => c.type.toLowerCase())
       // ?filters=product_cat[209]|ambiances[206-201]
       const filters = {
        product_cat: product_cat.map(pc => QUERYMAPPING_CATEGORY[pc]).filter(Boolean).join('-'),
        ambiances: ambiances.map(a => QUERYMAPPING_AMBIANCE[a]).filter(Boolean).join('-')
       }
       console.log({
        product_cat: product_cat,
        ambiances: ambiances
       })
       console.log({filters})
       const BASE_URL = `https://lesjumellessenmelent.fr/`
       const ENDPOINT = `votre-selection-de-produits-deco`
       window.location.href = `${BASE_URL}/${ENDPOINT}/?filters=product_cat[${filters.product_cat}]|ambiances[${filters.ambiances}]&from=ljsm`
    }

    render() {
        let widthProfil = this.state.width < 1100 ? "0%" : "auto"
        let widthQuizz = this.state.width < 1100 ? "100%" : "auto"
        let flexValueQuizz = this.state.width < 1100 ? "center" : "flex-end"
        let padding = this.state.width < 1100 ?  0 : 100
        return (
            <div style={{ backgroundImage: `url(${bgProfiling})`, paddingBottom: "10rem" }}>
                {
                    this.state.step.nbr > 7 ?
                        <div className={ this.state.width < 1100 ? "profiling-page" : "profiling-page profiling-page-padding"}>
                            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', marginRight: '2rem' }}>
                                <Quiz step={this.state.step} choices={this.state.choices} countClick={this.countClick} getPrimaryProfile={this.onCreateProfilSelection}></Quiz>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingRight: "1rem", paddingLeft: "1rem" }}>
                                <button onClick={this.onRestart} className="selection-btn-retry" style={{ marginRight: "1rem" }}>Refaire le questionnaire</button>
                                <Link onClick={this.handleSeeSelectionClick}><button className="selection-btn" style={{ marginLeft: "1rem" }}>Voir ma sélection d’objets</button></Link>
                            </div>
                        </div> :
                        <div>
                            <div className={ this.state.width < 1100 ? "profiling-page flex" : "profiling-page profiling-page-padding flex"}>
                                <div style={{ width: widthQuizz, display: 'flex', justifyContent: flexValueQuizz }} className="quiz-container">
                                    <Quiz step={this.state.step} choices={this.state.choices} countClick={this.countClick} getPrimaryProfile={this.onCreateProfilSelection}></Quiz>
                                </div>
                                <div style={{ width: widthProfil }}>
                                    <Profil width={this.state.width}></Profil>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <a><button onClick={this.onNextStep} className="selection-btn" style={{ width: "125px" }}>Suivant</button></a>
                            </div>
                        </div>
                }
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        stepProgress: state.stepProgress,
        answerList: state.answerList,
        number: state.number
    };
}

export default connect(mapStateToProps)(ProfilingPage);
