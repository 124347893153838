import React from 'react';
import { connect } from 'react-redux';

class Chip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
        }
    }

    onClickChip = () => {
        if (this.state.selected === false) {
            this.setState({selected: true});
            this.addTags();
        } else {
            this.setState({selected: false});
            this.deleteTags();
        }
    }


    deleteTags = () => {
        const {name} = this.props
        let listTags = this.props.packageTags || [];
        listTags = listTags.filter(v => v.name !== name)
        this.props.click(listTags);
        this.props.dispatch({type: "ADD_NEW_TAGS", packageTags: listTags});
    }


    addTags = () => {
        const {tags, name, packageTags} = this.props
        let listTags = packageTags || [];
        if (listTags.length > 0) {
            listTags.push({name: name,  tags: tags});
            this.props.click(listTags);
            // this.props.dispatch({type: "ADD_NEW_TAGS", packageTags: listTags});
        } else {
            listTags.push({name: name,  tags: tags});
            this.props.click(listTags);
            // this.props.dispatch({type: "ADD_NEW_TAGS", packageTags: listTags});
        }
    }

    render() {

        let classNameStyle = "unselected-type-pack"
        if (this.state.selected === true) {
            classNameStyle = "selected-type-pack"
        }
        return (
            <div onClick={this.onClickChip} className={classNameStyle}>
                <h2>{this.props.name}</h2>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        packageTags: state.packageTags,
    };
}

export default connect(mapStateToProps)(Chip);
