import React from 'react';
import './PackPage.css';
import Parse from 'parse';
import ChipList from '../../component/chip-list/ChipList'
import bgPack from '../../assets/pack/bg_shape_packs.svg'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class PackPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packs: [],
        }
    }

    componentDidMount() {
        document.getElementById("scroll-top").scrollIntoView(true);
    }

    render() {
        const {selectedPackage} = this.props;
        let listpack = selectedPackage || [];
        return (
            <>
            <div className="pack-page" style={{position: "relative"}}>
                <h1 className="pack-page-title">Découvrez nos Packs Déco</h1>
                <div style={{position: "relative"}}><img style={{position: "absolute", top: "1Opx", left: "-190px", zIndex: 0}} src={bgPack}/></div>
                <div style={{position: "relative"}}>
                    <ChipList></ChipList>
                </div>

                <div className="list-pack" style={{position: "relative"}}>
                    {
                        listpack.map((pack, key) =>
                        <Link key={key} to={{ pathname: `/item/${pack.id}`, state: { route: "/pack", name: "Découvrez nos Packs Déco" } }}>
                            <div className="pack-item">
                                <img src={pack.get("Image1")._url} />
                                <h2>{pack.get("Name")}</h2>
                                <div className="box-price-pack">
                                    <span>{(pack.get("PriceTTC") / 100).toFixed(2)} €</span>
                                </div>
                            </div>
                        </Link>
                        )
                    }
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedPackage: state.selectedPackage
    };
}

export default connect(mapStateToProps)(PackPage);
