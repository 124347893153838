import React from 'react';
import { connect } from 'react-redux';
import './Selection.css';
import addButton from '../../assets/shop/btn_more.svg'
import lessButton from '../../assets/shop/btn_less.svg'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Parse from 'parse';

class Selection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectionListTags: [],
            basket: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
      let retrievedDataBasket = localStorage.getItem("basket") || [];
      if (retrievedDataBasket.length > 0 && props.basketUpdated === true) {
        return { basket: JSON.parse(retrievedDataBasket) };
      }
      return null;
    }

    componentDidMount() {
        this.getInformation()
    }

    getInformation() {
        this.getBasket();
        this.getSelection();
    }

    getBasket() {
        let retrievedDataBasket = localStorage.getItem('basket') || [];
        if (retrievedDataBasket.length > 0) {
            this.setState({ basket: JSON.parse(retrievedDataBasket) });
            return JSON.parse(retrievedDataBasket)
        }
        return []
    }

    getSelection() {
        const retrievedData = localStorage.getItem('mySelection');

        if (retrievedData != null) {
            this.getAllItem(JSON.parse(retrievedData))
            return JSON.parse(retrievedData)
        }
        return []
    }

    async getAllItem(retrievedData) {
        const { colors, tags } = retrievedData;

        let Product = Parse.Object.extend("Product");

/*        const query = new Parse.Query(Product);
        query.containedIn("Tags", retrievedData);
        query.equalTo("Pack", false);
        query.equalTo("Validate", true);
        const results = await query.find();*/

        const tagsQuery = new Parse.Query(Product);
        tagsQuery.containedIn("Tags", tags);
        tagsQuery.equalTo("Pack", false);
        tagsQuery.equalTo("Validate", true);
        tagsQuery.limit(5000);

        const colorsQuery = new Parse.Query(Product);
        colorsQuery.containedIn("Tags", colors);
        colorsQuery.equalTo("Pack", false);
        colorsQuery.equalTo("Validate", true);
        tagsQuery.limit(5000);

        const query = Parse.Query.and(tagsQuery,  colorsQuery);
        const results = await query.find();

        this.setState({ selectionListTags: results });
    }

    checkItemIsInBasket(item) {
        const { basket } = this.state;
        let inBasket = false
        basket.forEach(itemBsk => {
            if (itemBsk.objectId.includes(item.id)) {
                inBasket = true;
            }
        })
        return inBasket;
    }


    addBasket = (item) => {
        let basket = this.getBasket();
        basket.push(item);
        localStorage.setItem('basket', JSON.stringify(basket));
        this.props.dispatch({ type: 'ADD_TO_BASKET', item: item});
    }

    deleteBasket = (item) => {
        let basket = this.getBasket();
        basket = basket.filter( v => v.objectId !== item.id );
        localStorage.setItem('basket', JSON.stringify(basket));
        this.props.dispatch({ type: 'UPDATE_BASKET', item: item});
    }

    onClick = (item) => {
        if (this.checkItemIsInBasket(item) === true) {
            this.deleteBasket(item)
            this.getInformation();
        } else {
            this.addBasket(item);
            this.getInformation()
        }
    }


    addButtonSign(item) {
        let image
        if (this.checkItemIsInBasket(item) === true) {
            image = lessButton;
        } else {
            image = addButton
        }
        return image
    }

    getUpdate = ()  => {
        this.getBasket();
        this.getSelection();
    }

    addAllItems(items) {
      items.forEach((item) => {
        this.addBasket(item);
      });
      this.getInformation();
    }

    render() {
        const { selectionListTags } = this.state

        return (
            <>
                <div>
                    <div className="selection-container">
                        {
                            selectionListTags.map((item, key) =>
                                <div key={key} className="selection-card">
                                    <div className="img-selection-card">
                                        <img onClick={() => this.onClick(item)} className="add-delete-img" src={this.addButtonSign(item)} />
                                        <Link to={{ pathname: `/item/${item.id}`, state: { route: "/shop", name: "La boutique" } }}><img onClick={() => {}} style={{ height: "166px", width: "166px", objectFit: "contain" }} src={item.get("Image1")._url} /></Link>
                                    </div>
                                    <Link to={{ pathname: `/item/${item.id}`, state: { route: "/shop", name: "La boutique" } }}><p>{item.get("Name")}</p></Link>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ width: "90%" }}>
                                            <span>{(item.get("PriceTTC") / 100).toFixed(2)} €</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <Link to={'/profiling'}><button className="selection-btn-retry" style={{ marginRight: "1rem" }}>Refaire mon profil déco</button></Link>
                        <button className="selection-btn" style={{ marginLeft: "1rem" }} onClick={ () => this.addAllItems(selectionListTags) }>Ajouter toute la selection à mon panier</button>
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        inProfiling: state.inProfiling
    };
}

export default connect(mapStateToProps)(Selection);
