import React from 'react';
import illustrationBackgroundHomepage from '../../assets/home/illustration_background_homepage.png'
import illustrationBackgroundHomepageMobile from '../../assets/home/bg_home_phone.png'
import whiteShapeBackgroundSteps from '../../assets/home/white_shape_background_steps.svg'
import iconLight from "../../assets/home/icon_light.svg"
import meublesIndustriel from "../../assets/home/meuble-industriel.png"
import iconPackPersonalize from "../../assets/home/icon_pack_personalize.svg"
import iconPersonalize from "../../assets/home/icon_personalize.svg"
import StartButton from '../../assets/home/start_button.svg'
import ButtonDownload from '../../assets/home/button_download.svg'
import mockupLJSM from '../../assets/home/mockup_ljsm.png'
import BgMockupApp from '../../assets/home/bg_mockup_app.png'
import ButtonPersonalizePack from "../../assets/home/button_personalize_pack.svg"
import ButtonJournal from "../../assets/home/button_journal.svg"
import Schema from "../../assets/home/schema.svg"
import ButtonReadyPack from "../../assets/home/button_ready_pack.svg"
import ShapeWhiteLeftPack from '../../assets/home/shape_white_left_pack.svg'
import ShapeWhiteRightPack from '../../assets/home/shape_white_right_pack.svg'
import FeedbackWhiteShape from '../../assets/home/feedback_white_shape.png'
import sendButton from '../../assets/home/send_button.svg'
import arrowPrevious from '../../assets/home/arrow_previous.svg'
import arrowNext from '../../assets/home/arrow_next.svg'
import { BrowserRouter as Router, Switch, Route, Link, withRouter } from 'react-router-dom';
import apostropheLeft from "../../assets/home/apostrophe_left.svg"
import apostropheRight from "../../assets/home/apostrophe_right.svg"
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import Snackbar from '@material-ui/core/Snackbar';
import BgUs from "../../assets/home/bg_white_us.svg"
import whiteShape22 from "../../assets/home/white_shape_22.svg"
import CookieConsent, { Cookies } from "react-cookie-consent";
import Twin1 from "../../assets/home/twin1.png"
import Twin2 from "../../assets/home/twin2.png"
import Twins from '../../assets/home/ljsm.jpg'
import MuiAlert from '@material-ui/lab/Alert';
import '@brainhubeu/react-carousel/lib/style.css';
import './Home.css';
import Parse from 'parse';
import Favorites from '../../component/favorites/Favorites';
import { HomeMobile } from './HomeMobile';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result: '',
            username: '',
            email: '',
            message: '',
            acceptUserOfInformation: false,
            open: false,
            width: 0,

            shouldScrollTop: false,
        };
        window.addEventListener("resize", this.update);
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate() {
        if (this.state.shouldScrollTop) {
            document.getElementById("scroll-top").scrollIntoView(true);
            this.setState({ shouldScrollTop: false });
        }
    }

    update = () => {
        this.setState({
            width: window.innerWidth,
            shouldScrollTop: true,
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    handleClick = () => {
        this.setState({ open: true });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };


    async sendContactUsMail(contactId) {
        const response = await Parse.Cloud.run("emailcheckout", {
            contactId: contactId
        });
    }

    mySubmitHandler = (event) => {
        const { username, email, message } = this.state
        event.preventDefault();
        if (email !== '' && message !== '') {
            var ContactUs = Parse.Object.extend("ContactUs");
            var contactUs = new ContactUs();

            contactUs.save({
                Email: email,
                Username: username,
                Content: message
            })
                .then((contactUs) => {
                    this.handleClick();
                    localStorage.setItem('user', JSON.stringify({ username: username, email: email }));
                    this.setState({ username: '', email: '', message: '' });
                    this.sendContactUsMail(contactUs.id);
                }, (error) => {
                });
        }
    };

    render() {
        const { acceptUserOfInformation, username, email, message } = this.state

        let homeTopStyle = this.state.width > 600 ? 'wrap-top-home' : 'wrap-top-home-responsive'
        let FontSizeValue = this.state.width > 600 ? '60px' : '40px'
        let FontSizeValueSub = this.state.width > 1200 ? '20px' : '18px'
        let responsiveContactUs = this.state.width > 1200 ? 'contact-form' : 'contact-form'
        let heightTitlePart = this.state.width > 600 ? '750px' : '586px'
        let DirectionAppPart = this.state.width > 1200 ? 'row' : 'column'
        let imgMockupResonsive = this.state.width > 1200 ? 'img-mockup' : 'img-mockup-responsive'
        let backgroundMobileFirstPart = this.state.width > 600 ? illustrationBackgroundHomepage : illustrationBackgroundHomepageMobile

        if (this.state.width < 800) {
            return <HomeMobile width={this.state.width} />
        }

        return (
            <div style={{ backgroundColor: '#FCFCFC', paddingTop: "5rem" }} className="padding-first">

                <div style={{
                    backgroundImage: `url(${backgroundMobileFirstPart})`,
                    height: heightTitlePart,
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className={homeTopStyle}>
                        <section className="home-presentation">
                            <h1 className="text-presentation" style={{ fontSize: FontSizeValue }}>En 5min  trouvez la déco qui vous ressemble</h1>
                            <p className="description-presentation" style={{ fontSize: FontSizeValueSub }}>En manque d'idées pour relooker une pièce? Les jumelles s'en mêlent vous proposent une sélection d'accessoires déco adaptés à votre profil. Et vous quel est votre profil?</p>
                            <div className="wrap-btn-presentation">
                                <Link to={'/profiling'}><img src={StartButton} /></Link>
                            </div>
                        </section>
                    </div>
                </div>

                {/* step */}
                <div className="steps-wrap margin-background-icon-list" style={{
                    backgroundImage: `url(${whiteShapeBackgroundSteps})`,
                    height: "650px",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "4%" }}>
                        <img style={{ width: "80%" }} src={Schema} />
                        <div style={{ width: "96%", display: "flex", textAlign: "center", justifyContent: "space-between" }}>
                            <div className="text-shema">
                                <span className="number-step">01</span>
                                <p className="description-step">Je définis mon profil</p>
                            </div>
                            <div className="text-shema">
                                <span className="number-step">02</span>
                                <p className="description-step">Je découvre le pack déco qui correspond à mon profil</p>
                            </div>
                            <div className="text-shema">
                                <span className="number-step">03</span>
                                <p className="description-step">Je personnalise et je commande mon pack</p>
                            </div>
                            <div className="text-shema">
                                <span className="number-step">04</span>
                                <p className="description-step">Livraison à domicile avec carnet personnalisé d'installation</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* step */}

                {/* pack */}
                <div style={{ width: "100%", textAlign: "center", display: "flex", justifyItems: "center", flexDirection: "column", alignItems: 'center' }}>
                    {
                        this.state.width > 1200 ? <video width="50%" height="30%" controls>
                            <source src="https://s3.eu-west-3.amazonaws.com/app.lesjumelles.public/intro_ljsm_youtube.mov" type="video/mp4" />
                        Your browser does not support the video tag or the file format of this video. <a href="http://www.supportduweb.com/">http://www.supportduweb.com/</a>
                        </video> : <video width="80%" height="60%" controls>
                                <source src="https://s3.eu-west-3.amazonaws.com/app.lesjumelles.public/intro_ljsm_youtube.mov" type="video/mp4" />
                        Your browser does not support the video tag or the file format of this video. <a href="http://www.supportduweb.com/">http://www.supportduweb.com/</a>
                            </video>
                    }
                    <h2 className="pack-title">Découvrez nos packs déco</h2>
                    <div style={{ width: "65%", display: "flex", flexWrap: "wrap", flexDirection: "row", display: "flex", justifyContent: "space-around", marginBottom: "2rem" }}>
                        <div className="pack">
                            <img style={{ position: "absolute", right: "62px", top: "-35px" }} src={iconLight} />
                            <img src={ShapeWhiteLeftPack} />
                            <h3>Besoin d'idées?</h3>
                            <p>Voici nos jolis Packs déco tendances, pensés par des architectes d’intérieur prêts à installer</p>
                            <Link to={'/pack'}><img src={ButtonReadyPack} /></Link>
                        </div>
                        <div className="pack">
                            <img style={{ position: "absolute", right: "78px", top: "8px" }} src={iconPackPersonalize} />
                            <img src={ShapeWhiteRightPack} />
                            <h3>Personnalisez</h3>
                            <p style={{ flex: 1, display: 'flex', alignItems: 'center' }}>Composez vous-même votre déco à partir des styles proposés dans la boutique</p>
                            <Link to={'/shop'}><img src={ButtonPersonalizePack} /></Link>
                        </div>
                        <div style={{ width: "100%", marginTop: "2em", fontStyle: "italic", color: "#041212", opacity: '0.52' }}>"Nous sommes là pour vous faciliter la mise en oeuvre, vous donner des idées d'installation et surtout nos conseils de pro."</div>
                    </div>
                </div>
                {/* pack */}

                {/* Découvrez nos coups de coeur */}
                <Favorites></Favorites>
                {/* Découvrez nos coups de coeur */}

                {/* qui somme nous */}
                <div className="heignt-us" style={{
                    backgroundImage: `url(${BgUs})`,
                    // height: "860px",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    justifyContent: 'center',
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    position: "relative",
                    flexWrap: 'wrap',
                    paddingRight: "0.5rem",
                    paddingLeft: "0.5rem",
                    margin: 'auto',
                    width: '80%',
                }}>
                    <h2 className="title-us" style={{ width: '100%' }}>Qui sommes-nous?</h2>
                    <img style={{ display: 'inline', objectFit: 'contain', width: '25%' }} src={Twins} />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: '5%', width: '65%' }}>
                        <p className="text-us">A la tête de cette aventure, nous sommes 2 sœurs jumelles, Laure Architecte d’intérieur et Séverine Décoratrice et Artiste peintre. Toutes les deux passionnées par la décoration, le design et les couleurs.</p>
                        <p className="text-us">Notre force, c’est notre complémentarité. Infatigables d’idées nouvelles, d’originalité et de bonne humeur ! Souvent sollicitées pour des projets d’aménagements intérieurs haut de gamme, nous avons souhaité donner accès à notre sélection de produits phares tout en proposant une expérience unique, facile et innovante. Les Jumelles s'en mêlent : un concept boutique innovant et engagé.  Soucieuses également d’élargir notre gamme vers des produits moins impactant sur l'environnement et plus éthiques, nous avons eu l'envie d’être une jolie vitrine pour une sélection d’artisans, designers ou fabricants alliant le beau et le côté éco-responsable. Persuadées que notre façon de décorer doit changer au profit d’intérieurs plus en harmonie avec nos nouveaux modes de vies et nos aspirations.</p>
                    </div>
                </div>
                {/* qui somme nous */}

                {/* journal */}
                <div className="journal">
                    <h2 className="journal-title">Le journal de la déco</h2>
                    <p>Découvrez les tendances déco, nos bons plans et astuces, des DiY et des articles, les <br /> évènements à ne pas louper,…</p>
                    <a href="https://lesjumellessenmelent.fr/blog-les-jumelles-sen-melent/"><img src={ButtonJournal} /></a>
                </div>
                {/* journal */}

                {/* feedback */}
                <div style={{
                    // backgroundImage: `url(${FeedbackWhiteShape})`,
                    backgroundImage: `url(${whiteShape22})`,
                    height: "750px",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    width: "100%",
                    zIndex: 11,
                    position: "relative"
                }}>
                    <div style={{
                        paddingTop: "270px",
                        display: "flex",
                        paddingLeft: "23%", paddingRight: "23%",
                        justifyContent: "flex-start"
                    }}>
                        <img src={apostropheLeft} />
                    </div>
                    <div style={{ paddingLeft: "12%", paddingRight: "12%" }}>
                        <Carousel
                            arrowLeft={<img src={arrowPrevious} />}
                            arrowRight={<img src={arrowNext} />}
                            slidesPerPage={1}
                            infinite
                            dots
                            addArrowClickHandler
                        >
                            <div className="feedback-wrap">
                                <span><strong>Aurélie</strong>, 40ans</span>
                                <p>L'ensemble de déco que les Jumelles m'ont proposé correspond parfaitement à mes attentes, les articles sont de qualité, le rendu est très professionnel, j'ai beaucoup aimé les petites idées déco glissées dans mon carton et le livret facilitant l'installation des accessoires</p>
                            </div>

                            <div className="feedback-wrap">
                                <span><strong>Anne-laure</strong>, 35ans</span>
                                <p>La livraison à domicile du pack déco m'a permis de gagner un temps précieux ! Je suis très contente du résultat</p>
                            </div>

                        </Carousel>
                        {/* <img style={{ position: "absolute", right: "0px", height: "635px", bottom: '76px' }} src={meublesIndustriel} /> */}
                    </div>
                    <div style={{
                        display: "flex",
                        paddingLeft: "23%", paddingRight: "23%",
                        justifyContent: "flex-end"
                    }}>
                        <img src={apostropheRight} />
                    </div>
                </div>
                {/* feedback */}

                {/* mockup */}

                <div style={{
                    backgroundImage: `url(${BgMockupApp})`,
                    height: "875px",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    display: "flex",
                    width: "100%",
                    zIndex: 10,
                    marginTop: "-8em",
                    justifyContent: "center",
                    flexDirection: DirectionAppPart,
                    alignItems: "center",
                    position: "relative"
                }}>
                    <div className="wrap-mockup">
                        <h2 className="title-mockup">Bientôt  l’App !</h2>
                        <p>Jouez à être votre propre décorateur</p>
                        <p style={{ marginBottom: "3rem" }}>Inscrivez-vous à la newsletter pour être les 1ers informés</p>
                        <a href="https://lesjumellessenmelent.fr/blog-les-jumelles-sen-melent/#newsletter"><img src={ButtonDownload} /></a>
                    </div>
                    <div className="wrap-mockup">
                        <img className={imgMockupResonsive} src={mockupLJSM} />
                    </div>
                </div>

                {/* mockup */}

                {/* contact */}
                <div className="wrap-contact">
                    <h2>Nous contacter</h2>
                    <form className={responsiveContactUs} onSubmit={this.mySubmitHandler}>
                        <input onChange={this.handleInputChange} value={username} placeholder="Nom" type="text" name="username" />
                        <input onChange={this.handleInputChange} value={email} placeholder="Email" type="email" name="email" />
                        <textarea onChange={this.handleInputChange} value={message} placeholder="Votre message" type="text" name="message" />
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "flex-start", marginBottom: "3rem" }}>
                            <input style={{ marginTop: "18px", minWidth: 0, minHeight: 0 }} type="checkbox" onChange={this.handleInputChange} value={acceptUserOfInformation} id="acceptUserOfInformation" name="acceptUserOfInformation" required />
                            <label className="text-us" for="acceptUserOfInformation" style={{ marginLeft: "10px" }}>J’autorise ce site à conserver mes données transmises via ce formulaire</label>
                        </div>
                        <button style={{ border: '0', backgroundColor: "transparent" }} type="submit"><img src={sendButton} /></button>
                    </form>
                </div>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="success">
                        Votre message a bien été envoyé.
                    </Alert>
                </Snackbar>
                {/* contact */}
            </div>
        );
    }
}

export default withRouter(Home);
