import React from 'react';
import './Favorites.css';
import { BrowserRouter as Link } from 'react-router-dom';
import whiteShape22 from "../../assets/home/white_shape_22.svg"
import arrowPrevious from '../../assets/home/arrow_previous.svg'
import arrowNext from '../../assets/home/arrow_next.svg'
import Carousel from '@brainhubeu/react-carousel';
import Parse from 'parse';

class Favorites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favorites: [],
        }
    }


    async componentDidMount() {
        let Product = Parse.Object.extend("Product");
        const query = new Parse.Query(Product);
        query.equalTo("Favorite", true);
        const results = await query.find();
        this.setState({ favorites: results })
    }

    mobileCarrouselVersion = () => {
        return (
            <>
                <div className="fav-mobile" style={{
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    width: "100%",
                    zIndex: 11,
                    marginTop: "2rem",
                    textAlign: "center",
                }}>
                    <section className="section-favorite">
                        <Carousel
                            arrowLeft={<img src={arrowPrevious} />}
                            arrowRight={<img src={arrowNext} />}
                            slidesPerPage={4}
                            infinite
                            breakpoints={{
                                100: {
                                    slidesPerPage: 1,
                                },
                                640: {
                                    slidesPerPage: 1,
                                },
                                900: {
                                    slidesPerPage: 2,
                                },
                                1100: {
                                    slidesPerPage: 3,
                                }
                            }}
                            // itemWidth={200}
                            addArrowClickHandler
                        >
                            {
                                this.state.favorites.map((favorite, key) =>
                                    <div className="item-fav" key={key}>
                                        {/* <Link key={key}to={`/item/${favorite.id}`}> */}
                                        {/* <Link key={key}to={'/profile'}> */}
                                        <a href={`/item/${favorite.id}`}><img style={{ width: "191px" }} src={favorite.get("Image1") ? favorite.get("Image1")._url : undefined} /></a>
                                        {/* </Link> */}
                                        <p>{favorite.get("Name")}</p>
                                        <span>{favorite.get("Description").substring(0, 33)}...</span>
                                    </div>
                                )
                            }
                        </Carousel>
                    </section>
                </div>
            </>
        )
    }

    render() {
        if (this.props.width < 800) {
            return (<>{this.mobileCarrouselVersion()}</>)
        }
        return (
            <>
                {/* Découvrez nos coups de coeur */}
                <div className="fav-wrap" style={{
                    backgroundImage: `url(${whiteShape22})`,
                    height: "800px",
                    backgroundSize: "cover",
                    backgroundRepeat: 'no-repeat',
                    width: "100%",
                    zIndex: 11,
                    textAlign: "center",
                }}>
                    <div style={{ paddingRight: "20%", paddingLeft: "20%", paddingTop: "150px" }}>
                        <h2>Découvrez nos coups de coeur</h2>
                        <p>Une sélection de pépites à la carte, produits uniques ou limités, issus des plus belles marques, de l’artisanat ou du design, ou simplement les must have à avoir pour être dans les tendances actuelles.</p>
                    </div>
                    <section className="section-favorite">
                        <Carousel
                            arrowLeft={<img src={arrowPrevious} />}
                            arrowRight={<img src={arrowNext} />}
                            slidesPerPage={4}
                            infinite
                            breakpoints={{
                                100: {
                                    slidesPerPage: 1,
                                },
                                640: {
                                    slidesPerPage: 1,
                                },
                                900: {
                                    slidesPerPage: 2,
                                },
                                1100: {
                                    slidesPerPage: 3,
                                }
                            }}
                            // itemWidth={200}
                            addArrowClickHandler
                        >
                            {
                                this.state.favorites.map((favorite, key) =>
                                    <div className="item-fav">
                                        {/* <Link key={key}to={`/item/${favorite.id}`}> */}
                                        {/* <Link key={key}to={'/profile'}> */}
                                        <a href={`/item/${favorite.id}`}><img style={{ width: "191px" }} src={favorite.get("Image1") ? favorite.get("Image1")._url : undefined} /></a>
                                        {/* </Link> */}
                                        <p>{favorite.get("Name")}</p>
                                        <span>{favorite.get("Description").substring(0, 33)}...</span>
                                    </div>
                                )
                            }
                        </Carousel>
                    </section>
                </div>
            </>
        )
    }
}


export default Favorites;
