import React from 'react';
import Parse from 'parse';
import './GiftCardPage.css';
import addBasketWhite from '../../assets/home/add_basket_white.svg'
import giftCardImg from '../../assets/giftcard/gift_card.png'
import { connect } from 'react-redux';


class GiftCardPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          amount: 0,
          alertMessage: undefined,
      }
      this.addBasket = this.addBasket.bind(this);
      this.removeAlert = this.removeAlert.bind(this);
    }

    componentDidMount() {
        document.getElementById("scroll-top").scrollIntoView(true);
    }

    handleChange = (evt) => {
        this.setState({ amount: evt.target.value })
    }

    removeAlert() {
      this.setState({ alertMessage: undefined });
    }

    addBasket = async (evt) => {
      const {amount} = this.state
      evt.preventDefault();

      if (!amount || amount == 0) {
        this.setState({
          alertMessage: "Veuillez renseigner une valeur supérieure à zéro",
        });
        return setTimeout(this.removeAlert, 2000);
      }

      const Product = Parse.Object.extend("Product");
      const giftCard = await new Parse.Query(Product)
      .equalTo("IsGiftCard", true)
      .first();

      let retrievedDataBasket = localStorage.getItem("basket");
      if (retrievedDataBasket === null) {
        retrievedDataBasket = "[]";
      }

      let basket = JSON.parse(retrievedDataBasket);
      basket.push(giftCard);

      let retrivedDataGiftCards = localStorage.getItem("giftCardsValue");
      if (retrivedDataGiftCards === null) {
        retrivedDataGiftCards = "[]";
      }

      const buyingGiftCards = JSON.parse(retrivedDataGiftCards);
      buyingGiftCards.push(amount * 100);

      localStorage.setItem("basket", JSON.stringify(basket));
      localStorage.setItem("giftCardsValue", JSON.stringify(buyingGiftCards));
      this.props.dispatch({ type: 'ADD_TO_BASKET', item: giftCard});

      this.setState({
        alertMessage: "Une carte cadeau a été ajoutée à votre panier",
      });
      return setTimeout(this.removeAlert, 2000);
    }

    render () {
        const {amount} = this.state;
        return (
            <>
                <div className="giftcard-page">
                    {this.state.alertMessage && <div style={{ position: "absolute", display: "flex", justifyContent: "center", left: 0, top: 0, width: "100%", textAlign: "center" }}>
                      <div style={{ padding: "10px 30px", borderRadius: "20px", color: "white", backgroundColor: "#308588", opacity: 0.8 }}>{this.state.alertMessage}</div>
                    </div>}
                    <div className="gifcard-img-container">
                        <img className="giftcard" src={giftCardImg} />
                    </div>
                    <form className="gifcard-info-container" onSubmit={this.addBasket}>
                        <h1>Carte cadeau</h1>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div onClick={(e)  => this.addBasket(e)} className="backet">
                                    <img src={addBasketWhite} />
                                </div>
                                <span>{amount}€</span>
                            </div>
                            <input onChange={this.handleChange} className='custom-input-amount' type="number" name="amount" placeholder="Choix du montant" />
                        </div>
                        <p>Pour offrir une expérience inédite en décoration, optez pour la Carte Cadeau.
                        Elle fera plaisir à coup sûr!</p>
                        <p style={{margin: 0}}>La carte cadeau Les Jumelles s'en mêlent est valable pendant un an sur l’ensemble de l’e-shop.</p>
                        <p style={{margin: 0}}>Pour offrir, comment ça marche ?</p>
                        <ul className="list-gift">
                            <li>vous choisissez le montant</li>
                            <li>une fois réglée, votre carte sera envoyée par e-mail à l'adresse mail que vous aurez renseignée</li>
                            <li>le destinataire peut utiliser son code personnel qui lui permettra de régler sa commande</li>
                            <li>il pourra l’utiliser en plusieurs fois</li>
                        </ul>
                        <p>Pour utiliser votre carte cadeau</p>
                        <ul className="list-gift">
                            <li>effectuez vos achats sur le site</li>
                            <li>entrez le code de la carte cadeau en fin de commande</li>
                        </ul>
                    </form>
                </div>
            </>
        )
    }
}

export default connect(() => ({}))(GiftCardPage);
