import React, { useEffect } from 'react';
import logoLJSM from '../../assets/navbar/logo_rounded.svg'
import basket from '../../assets/navbar/basket.svg'
import { BrowserRouter as Router, Switch, Route, Link, withRouter, useLocation } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from 'react-redux';
import './Navbar.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = React.useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}

const Navbar = ({ stepProgress }) => {
    const classes = useStyles();
    let location = useLocation()
    let [isProfiling, setIsProfiling] = React.useState(false);
    const size = useWindowSize();
    const [state, setState] = React.useState({
        top: false,
    });

    useEffect((item) => {
        setIsProfiling(location.pathname.includes('profiling'))
    }, [location])

    const handleClickMenu = () => {
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <a href="https://lesjumellessenmelent.fr"><ListItem button>
                    <ListItemText primary={"Nos prestations"} />
                </ListItem></a>
                <a href="https://lesjumellessenmelent.fr/les-packs-deco/"><ListItem button>
                    <ListItemText primary={"Nos packs"} />
                </ListItem></a>
                <a href="https://lesjumellessenmelent.fr/panier"><ListItem button>
                    <ListItemText primary={"Boutique"} />
                </ListItem></a>
                <a href="https://lesjumellessenmelent.fr/le-blog-des-jumelles/"><ListItem button>
                    <ListItemText primary={"Notre actualité"} />
                </ListItem></a>
                <a href="https://lesjumellessenmelent.fr/mon-compte"><ListItem button>
                    <ListItemText primary={"Mon compte"} />
                </ListItem></a>
            </List>
        </div>
    );


    /*
    Nos prestations : https://lesjumellessenmelent.fr
Profil déco : (tout dépend du choix de la question 1)
Nos packs : https://lesjumellessenmelent.fr/les-packs-deco/
Boutique : https://lesjumellessenmelent.fr/boutique-deco/
Notre actualité : https://lesjumellessenmelent.fr/le-blog-des-jumelles/
Mon compte :  https://lesjumellessenmelent.fr/mon-compte
Icone panier :  https://lesjumellessenmelent.fr/panier
    */
    const navbarLink = () => {
        return (
            <>
                {
                    size.width > 800 ?
                        <nav hidden={isProfiling} className="navbar">
                            <ul className="navbar-nav">
                                <li><Link to={'/'}><img src={logoLJSM} /></Link></li>
                                <li><a href="https://lesjumellessenmelent.fr">Nos prestations</a></li>
                                <li><Link to={'/'} className="nav-link">Profil déco</Link></li>
                                <li><a href="https://lesjumellessenmelent.fr/les-packs-deco/">Nos Packs</a></li>
                                <li><a href="https://lesjumellessenmelent.fr/boutique-deco/">Boutique</a></li>
                                <li><a href="https://lesjumellessenmelent.fr/le-blog-des-jumelles/">Notre actualité</a></li>
                                <li><a href="https://lesjumellessenmelent.fr/mon-compte">Mon compte</a></li>
                                <li><a href="https://lesjumellessenmelent.fr/panier"><img src={basket} /></a></li>
                            </ul>
                        </nav> :
                        <>
                            <Drawer anchor={"top"} open={state["top"]} onClose={toggleDrawer("top", false)}>
                                {list("top")}
                            </Drawer>
                            <AppBar position="static">
                                <Toolbar>
                                    <IconButton onClick={toggleDrawer("top", true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                        <MenuIcon />
                                    </IconButton>
                                    <IconButton className={classes.title} aria-label="menu">
                                        <Link to={'/'}><img style={{width: "50px"}} src={logoLJSM} /></Link>
                                    </IconButton>
                                    <IconButton  aria-label="menu">
                                    <a href="https://lesjumellessenmelent.fr/panier"><img src={basket} /></a>
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                        </>
                }
            </>
        )
    }
    return (
        <div id="scroll-top">
            {
                isProfiling ?
                    (<>
                        <nav hidden={!isProfiling} className="navbar-profiling">
                            <Link to={'/'}><img src={logoLJSM} /></Link>
                        </nav>
                        <LinearProgress variant="determinate" value={stepProgress} />
                    </>
                    ) : (navbarLink())
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        stepProgress: state.stepProgress
    };
}

export default connect(mapStateToProps)(withRouter(Navbar));
// export default Navbar;
