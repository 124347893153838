import React from 'react';

const Test = () => {
    return (
        <>
        <h1>TEST</h1>
        </>
    );
}

export default Test