import React from 'react';
import './Footer.css';
import footerShape from '../../assets/home/bottom_shape.svg'
import instagramLogo from '../../assets/home/instagram.svg'
import facebookLogo from '../../assets/home/facebook.svg'
import logoLJSM from '../../assets/navbar/logo_rounded.svg'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

class Footer extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="footer">
                <section className="wrap-footer">
                    <section>
                        <span>Menu</span>
                        <ul>
                            <Link to={'/'}><li>Accueil</li></Link>
                            <Link to={'/pack'}><li>Les packs déco</li></Link>
                            <Link to={'/shop'}><li>Notre boutique</li></Link>
                            {/* <Link to={'/shop'}><li>Le journal de la déco</li></Link> */}
                            <li><a href="https://lesjumellessenmelent.fr/blog-les-jumelles-sen-melent/">Le blog</a></li>
                            <Link to={'/commande'}><li>Mon panier</li></Link>
                            <Link to={'/giftcard'}><li>Carte cadeau</li></Link>
                            <li><a target="_blank" href="https://lesjumellessenmelent.fr/blog-les-jumelles-sen-melent/#newsletter">S’inscrire à la Newsletter</a></li>
                        </ul>
                    </section>
                    <section>
                        <span><a href="https://lesjumellessenmelent.fr/mentions-legales/">Mentions légales</a></span>
                        <ul>
                            <li><a href="https://lesjumellessenmelent.fr/conditions-generales-dutilisation/">Conditions Générales d’utilisation et politique de confidentialité</a></li>
                            <li><a href="https://lesjumellessenmelent.fr/condition-generales-de-vente/">Conditions Générales de Vente</a></li>
                        </ul>
                    </section>
                    <section>
                        <span>Réseaux sociaux</span>
                        <section style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "1rem" }}>
                            <a href="https://www.instagram.com/lesjumellesdeco/" target="_blank"><img style={{ marginRight: "1rem" }} src={instagramLogo} /></a>
                            <a href="https://www.facebook.com/LJSMdeco/" target="_blank"    ><img src={facebookLogo} /></a>
                        </section>

                    </section>
                </section>
                <hr width="80%" color="#EEEEEE"></hr>
                <section className="footer-copyright-wrap">
                    <Link to={'/'}><img src={logoLJSM} /></Link>
                    <span>© Copyright 2020 - Les jumelles s’en mêlent</span>
                </section>
                <section style={{ display: "flex", justifyContent: "flex-end" }}>
                    <img src={footerShape} />
                </section>
            </div>
        )
    }
}

export default Footer;
