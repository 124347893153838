import React, { useState, useEffect } from 'react';
import { Flex } from 'react-flex-material';
import logoLJSM from '../../../assets/navbar/logo_rounded.svg'

import "./MobileSelection.css"
export const MobileSelection = ({ colorList, pictureList }) => {
    return (
        <div style={{ padding: "1rem" }}>
            <div style={{ width: "100%", height: "300px", display: 'flex', marginBottom: '1rem', justifyContent: "space-between" }}>
                <div style={{ width: "78%", heigh: "300px", borderRadius: '10px' }}><img style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }} src={pictureList[0]} /></div>
                <div style={{ width: "20%", borderRadius: '10px' }}>

                    <div style={{ height: '55px', borderRadius: '10px' }}><img style={{ width: '100%' }} src={logoLJSM} /></div>
                    {
                        colorList[1] ? <><div style={{ height: '55px', backgroundColor: `${colorList[1][0]}`, borderRadius: '10px', marginTop: '5px' }}></div><div style={{ height: '55px', backgroundColor: `${colorList[1][1]}`, borderRadius: '10px', marginTop: '5px' }}></div></>
                                     : <><div style={{ height: '55px', backgroundColor: 'white', borderRadius: '10px', marginTop: '5px' }}></div><div style={{ height: '55px', backgroundColor: 'white', borderRadius: '10px', marginTop: '5px' }}></div></>
                    }
                    <div style={{ height: '55px', backgroundColor: `${colorList[0][0]}`, borderRadius: '10px', marginTop: '5px' }}></div>
                    <div style={{ height: '55px', backgroundColor: `${colorList[0][1]}`, borderRadius: '10px', marginTop: '5px' }}></div>
                    {/* <div style={{height: '55px', backgroundColor: 'green', borderRadius: '10px', marginTop: '5px'}}></div>
                    <div style={{height: '55px', backgroundColor: 'green', borderRadius: '10px', marginTop: '5px'}}></div>
                    <div style={{height: '55px', backgroundColor: 'green', borderRadius: '10px', marginTop: '5px'}}></div>
                    <div style={{height: '55px', backgroundColor: 'green', borderRadius: '10px', marginTop: '5px'}}></div> */}
                </div>
            </div>
            <div style={{ display: 'flex', width: '100%', height: '150px', justifyContent: "space-between" }}>
                <div style={{ width: '48%', borderRadius: '10px' }}><img style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }} src={pictureList[1]} /></div>
                <div style={{ width: '48%', borderRadius: '10px' }}><img img style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }} src={pictureList[5]} /></div>
            </div>
            <div style={{ display: 'flex', width: '100%', height: '150px', marginTop: '1rem', justifyContent: "space-between" }}>
                <div style={{ width: '48%', borderRadius: '10px' }}><img img style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }} src={pictureList[2]} /></div>
                <div style={{ width: '48%', borderRadius: '10px' }}><img img style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }} src={pictureList[6]} /></div>
            </div>
        </div>
    )
}

export default MobileSelection;
