import React from "react";
import "./StripeSuccess.css";

class StripeSuccess extends React.Component {
  componentDidMount() {
    localStorage.removeItem("basket");
    localStorage.removeItem("giftCardsValue");
  }

  render() {
    return (
      <div className="stripe-success">
        <div className="stripe-success__container">
          <div className="stripe-success__border">
            <div className="stripe-success__title">Votre commande a bien été prise en compte.</div>
            <div className="stripe-success__description">Les Jumelles s'en Mêlent vous remercient pour votre commande. Vous recevrez un mail vous indiquant où accéder au suivi de votre colis.</div>
            <div className="stripe-success__warning">Pensez à vérifier vos courriers indésirables!</div>
          </div>
        </div>
      </div>
    )
  }
};

export default StripeSuccess;
