import React from 'react';
import Parse from 'parse';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import './RecapPage.css';
import { connect } from 'react-redux';

class RecapPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basket: [],
            quantities: {},
            giftCardId: undefined,
            isPercent: false,
            giftCard: "",
            giftCardAmount: undefined,
            errorNumber: false,
            showHint: false,
            width: 0,

            shouldScrollTop: false,
        }

      this.handleSubmit = this.handleSubmit.bind(this);
      this.checkIfGiftCardIsValid = this.checkIfGiftCardIsValid.bind(this);
      window.addEventListener("resize", this.update);
    }

    componentDidMount() {
      this.update();
        this.getBasket();
        this.props.dispatch({type: "UPDATE_BASKET"});
    }

    componentDidUpdate() {
        if (this.state.shouldScrollTop) {
            document.getElementById("scroll-top").scrollIntoView(true);
            this.setState({shouldScrollTop: false});
        }
    }

    update = () => {
      this.setState({
          width: window.innerWidth
      });
  };

    componentWillReceiveProps(nextProps) {
      const quantities = nextProps.basketList.reduce((qtts, item) => {
        qtts[item.objectId] = qtts[item.objectId] ? qtts[item.objectId] + 1 : 1 ;

        return qtts;
      }, {});

      this.setState({basket: nextProps.basketList, quantities});
    }


    getBasket() {
        let retrievedDataBasket = localStorage.getItem('basket') || [];
        if (retrievedDataBasket.length > 0) {
            this.setState({ basket: JSON.parse(retrievedDataBasket), shouldScrollTop: true });
            return JSON.parse(retrievedDataBasket)
        }
        this.setState({ basket: [], shouldScrollTop: true });
        return [];
    }

    deleteBasket = (item, giftCardValue) => {
      let basket = this.getBasket();

      if (item.IsGiftCard) {
        let retrievedData = localStorage.getItem("giftCardsValue");
        if (retrievedData === null) {
          retrievedData = "[]";
        }

        let giftCardsValue = JSON.parse(retrievedData);
        let indexOfValue = giftCardsValue.findIndex((v) => v === giftCardValue);
        giftCardsValue = giftCardsValue.filter((v, index) => index !== indexOfValue);
        localStorage.setItem("giftCardsValue", JSON.stringify(giftCardsValue));

        let indexOfItem = basket.findIndex((v) => v.objectId === item.objectId);
        basket = basket.filter((v, index) => index !== indexOfItem);
        localStorage.setItem("basket", JSON.stringify(basket));
        this.props.dispatch({ type: "UPDATE_BASKET", item: item});
      } else {
        basket = basket.filter(v => v.objectId !== item.objectId);
        localStorage.setItem('basket', JSON.stringify(basket));
        this.getBasket();
        this.props.dispatch({ type: 'UPDATE_BASKET', item: item});
      }
    }

    totalPrice = () => {
        if (this.state.basket.length) {
            this.state.basket.map((item) => item.PriceTTC * 1)
            let itemsPrice = 0
            for (let i = 0; i < this.state.basket.length; i++) {
                itemsPrice = itemsPrice + this.state.basket[i].PriceTTC * 1;
            }

            let retrievedData = localStorage.getItem("giftCardsValue");
            if (retrievedData === null) {
              retrievedData = "[]";
            }

            const giftCardsValue = JSON.parse(retrievedData);

            for (let i = 0; i < giftCardsValue.length; i++) {
              itemsPrice = itemsPrice + giftCardsValue[i];
            }

            return itemsPrice
        }
        return 0
    }

    getDeliveryPrice(totalPrice) {
      const basket =  this.state.basket.filter((item) => !item.IsGiftCard);

      if (basket.length === 0)
        return 0;

      let totalDelivery = 0;

      let itemsNumber = 0;

      basket.forEach((product) => {
        if (product.Pack) {
          totalDelivery = totalDelivery + 1199;
        } else {
          itemsNumber++;
        }
      });

      if (itemsNumber >= 6) {
        return totalDelivery + 1199;
      } else if (itemsNumber >= 4) {
        return totalDelivery + 999;
      } else if (itemsNumber >= 2) {
        return totalDelivery + 499;
      } else {
        return totalDelivery + 299;
      }

      return totalDelivery;
    }

    async checkIfGiftCardIsValid() {
      const GiftCard = Parse.Object.extend("Gift");

      let giftCard = undefined;
      try {
        giftCard = await new Parse.Query(GiftCard).get(this.state.giftCard);
      } catch (err) {
        if (!giftCard) {
          alert("Carte cadeau invalide.");
          return this.setState({ giftCard: undefined, giftCardAmount: undefined, isPercent: false, giftCardId: undefined });
        }
      }

      if (giftCard.get("UsedBy").length >= giftCard.get("MaximumUse")) {
        alert("Carte cadeau invalide.");
        return this.setState({ giftCard: undefined, giftCardAmount: undefined, isPercent: false, giftCardId: undefined });
      } else if (giftCard.get("StartDate") > new Date() || new Date() > giftCard.get("EndDate")) {
        alert("Carte cadeau invalide.");
        return this.setState({ giftCard: undefined, giftCardAmount: undefined, isPercent: false, giftCardId: undefined });
      }

      if (giftCard.get("IsPercent")) {
        this.setState({ giftCardAmount: giftCard.get("Percent"), isPercent: true, giftCardId: giftCard.id })
      } else {
        this.setState({ giftCardAmount: giftCard.get("Value"), isPercent: false, giftCardId: giftCard.id })
      }
    }

    getAllId = (selectedProducts) => {
      let product = JSON.parse(selectedProducts)
      product = product.map(product => product.objectId);
      return product
    }

    handleQuantity(item, type) {
      let basket = [];

      let retrievedDataBasket = localStorage.getItem('basket') || [];
      if (retrievedDataBasket.length > 0) {
          basket = JSON.parse(retrievedDataBasket);
      }

      if (type === "add") {
        basket.push(item);
        localStorage.setItem("basket", JSON.stringify(basket));
        this.props.dispatch({ type: "ADD_TO_BASKET", item });
      } else {
        let indexOfItem = basket.findIndex((v) => v.objectId === item.objectId);

        basket = basket.filter((v, index) => index !== indexOfItem);
        localStorage.setItem("basket", JSON.stringify(basket));
        this.props.dispatch({ type: "UPDATE_BASKET", item: item});
      }
    }

    async handleSubmit(evt) {
      evt.preventDefault();
      let selectedProductsIdsAsString = localStorage.getItem('basket') || [];
      if (evt.target["phonenumber"].value.length < 10) {
        this.setState({errorNumber: true})
        return
      }

      let retrievedData = localStorage.getItem("giftCardsValue");
      if (retrievedData === null) {
        retrievedData = "[]";
      }

      const giftCardsValue = JSON.parse(retrievedData);

      const response = await Parse.Cloud.run("checkout", {
        selectedProductsIds: this.getAllId(selectedProductsIdsAsString),
        primaryContact: evt.target["contact"].value.toLowerCase(),
        firstName: evt.target["firstname"].value,
        lastName: evt.target["lastname"].value,
        company: evt.target["company"].value,
        street: evt.target["address"].value,
        apartment: evt.target["apartment"].value,
        postalCode: evt.target["postalcode"].value,
        city: evt.target["city"].value,
        country: evt.target["country"].value,
        phoneNumber: evt.target["phonenumber"].value,
        useOfUserData: (evt.target["collectedinformations"].value) ? true : undefined,
        baseAmount: this.totalPrice(),
        giftCard: this.state.giftCardId,
        deliveryAmount: this.getDeliveryPrice(),
        giftCardsValue,
      });

      if (response.error) {
        return alert(response.error);
      }

      if (response.redirectURL) {
        return window.location.replace(response.redirectURL);
      }

      // const stripe = window.Stripe("pk_test_93NYcOi5DcDURQHQ5U1hmYLG00hc0y4Lye");
        const stripe = window.Stripe("pk_live_nuj7I6r0TA8fxOSrcaorNZZl00hKd7EWux");

      await stripe.redirectToCheckout({ sessionId: response.id });
    }

    render() {
        const {errorNumber, width} = this.state
        let sousTotal = this.totalPrice();

        let { basket } = this.state;

        basket = basket.reduce((bl, item) => {
          if (!item.IsGiftCard && bl.find((i) => i.objectId === item.objectId)) {
            return bl;
          } else {
            bl.push(item);
            return bl;
          }
        }, []);


        let giftCardAmount = 0;
        if (this.state.giftCardAmount) {
          if (this.state.isPercent) {
            giftCardAmount = -(sousTotal * (this.state.giftCardAmount / 100) / 100);
          } else if (this.state.giftCardAmount >= sousTotal) {
            giftCardAmount = -sousTotal;
          } else {
            giftCardAmount = -this.state.giftCardAmount
          }
        }

        let retrievedData = localStorage.getItem("giftCardsValue");
        if (retrievedData === null) {
          retrievedData = "[]";
        }

        const giftCardsValue = JSON.parse(retrievedData);

        let i = -1;

        let total = sousTotal + giftCardAmount + this.getDeliveryPrice(sousTotal);

        return (
            <div className="recap-container">
                {/* information */}
                <div className="recap">
                    <h1 className="title-recap">Votre commande</h1>
                    <p>Commande > Informations > Paiement</p>
                    <form style={{ display: "flex", flexDirection: "column" }} onSubmit={ this.handleSubmit }>
                        <h2>Coordonnées</h2>
                        <input className='custom-input-recap' type="email" name="contact" placeholder="E-mail" required />
                        <h2>Adresse de livraison</h2>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <input className='custom-input-recap' type="text" name="firstname" placeholder="Prénom" required />
                            <input className='custom-input-recap' type="text" name="lastname" placeholder="Nom" required />
                        </div>
                        <input className='custom-input-recap' type="text" name="company" placeholder="Raison sociale" />
                        <input className='custom-input-recap' type="text" name="address" placeholder="Adresse" required />
                        <input className='custom-input-recap' type="text" name="apartment" placeholder="Appartement, suite, …" />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <input className='custom-input-recap' type="text" name="postalcode" placeholder="C.postal" required />
                            <input className='custom-input-recap' type="text" name="city" placeholder="Ville" required />
                            <input className='custom-input-recap' type="text" name="country" placeholder="Pays" required />
                        </div>
                        <input className='custom-input-recap' type="number" name="phonenumber" placeholder="Téléphone" />
                        <span className="error" hidden={!errorNumber}>Numéro de téléphone non valide</span>
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}>
                            <label>
                                <input name="generalcondition" type="checkbox" required />
                                J’accepte les conditions générales de vente.
                            </label>
                            <label>
                                <input name="collectedinformations" type="checkbox" />
                                J’accepte que mes informations soient exploitées dans le cadre de ma commande sur le site.
                            </label>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <button className="selection-btn" type="submit" disabled={ this.state.basket.length === 0 }>Suivant</button>
                        </div>
                    </form>
                </div>
                {/* information */}

                <div hidden={width < 800} className="vl" ></div>

                {/* item-part */}
                <div className="recap-list-container">
                    {/* item-recap */}
                    <div className="recap-list">
                    {
                        basket.map((item, index) => {
                            if (item.IsGiftCard) {
                              i = i + 1;
                            }

                            return (
                              <>
                              <div className="item-basket">
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginRight: '5px' }}>
                                      <img className="item-basket-img-box" src={item.Image1.url} />
                                      <span className="delete-item-basket" style={{ color: "black" }} onClick={() => this.deleteBasket(item, giftCardsValue[i])}>Supprimer</span>
                                  </div>
                                  <div style={{ width: '70%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                      <span className="title-item-basket" style={{ color: "black" }}>{item.Name}</span>
                                      <div style={{ textAlign: 'right' }}>
                                          <span className="price-item-basket" style={{ color: "#308588" }}>{(item.IsGiftCard) ? (giftCardsValue[i] / 100).toFixed(2) : (item.PriceTTC * this.state.quantities[item.objectId] / 100).toFixed(2)} €</span>
                                      </div>
                                      {!item.IsGiftCard && <div style={{ alignItems: "center", display: "flex"}}>
                                        <IconButton onClick={ () => this.handleQuantity(item, "remove") } size="small" style={{ color: "white", cursor: "pointer", marginRight: "5px"}}>
                                          <RemoveIcon />
                                        </IconButton>
                                        <span className="nbr-of-item" style={{ color: "black" }}>Quantité: { this.state.quantities[item.objectId] }</span>
                                          { this.state.quantities[item.objectId] < item.Stock && <IconButton onClick={ () => this.handleQuantity(item, "add") } size="small" style={{ color: "white", cursor: "pointer", marginLeft: "5px"}}>
                                          <AddIcon />
                                        </IconButton> }
                                      </div> }
                                  </div>
                              </div>
                              <hr className="hr-item-basket" />
                              </>
                            )
                          })

                            // return (
                            //   <div key={index} style={{ display: "flex", alignContent: "center", marginTop: "2rem" }}>
                            //       <img style={{ height: '74px', width: '74px', marginRight: "2rem" }} src={item.Image1.url} />
                            //       <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            //           <p className="title-item-recap">{item.Name}</p>
                            //           {!item.IsGiftCard && <div style={{ alignItems: "center", display: "flex", flexGrow: 1}}>
                            //             <IconButton onClick={ () => this.handleQuantity(item, "remove") } size="small" style={{ color: "white", cursor: "pointer", marginRight: "5px"}}>
                            //               <RemoveIcon />
                            //             </IconButton>
                            //             <span style={{ color: "black", fontSize: "13px" }}>Quantité: { this.state.quantities[item.objectId] }</span>
                            //             <IconButton onClick={ () => this.handleQuantity(item, "add") } size="small" style={{ color: "white", cursor: "pointer", marginLeft: "5px"}}>
                            //               <AddIcon />
                            //             </IconButton>
                            //           </div>}
                            //           <p className="price-item-recap">{(item.IsGiftCard) ? (giftCardsValue[i] / 100).toFixed(2) : (item.PriceTTC / 100).toFixed(2)} €</p>
                            //           <span className="delete-item-recap" onClick={() => this.deleteBasket(item)}>Supprimer</span>
                            //       </div>
                            //   </div>
                            // )
                        // })
                    }
                    </div>
                    {/* item-recap */}
                    <hr className="hr-recap" />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span className="sous-total">Sous-total</span>
                        <span className="sous-total">{(sousTotal / 100).toFixed(2)} €</span>
                    </div>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between", marginTop: "0.5rem" }}>
                        <span className="shipping" style={{ alignItems: "center", display: "flex", position: "relative" }}>
                          Livraison
                          <InfoOutlinedIcon
                            onClick={() => this.setState({showHint: !this.state.showHint})}
                            style={{ height: "20px", width: "20px", marginLeft: "5px" }} />
                            {this.state.showHint && <div id="delivery-hint" style={{ position: "absolute", backgroundColor: "white", top: "95%", border: "1px solid black", borderRadius: "10px", padding: "5px 10px", fontSize: "13px", width: "400%" }}>
                          Veuillez consulter les <a href="https://lesjumellessenmelent.fr/condition-generales-de-vente/">CGV</a> pour connaitre les conditions et les tarifs de livraison.
                        </div>}
                        </span>
                        <span className="shipping">{ `${ (this.getDeliveryPrice(sousTotal) / 100).toFixed(2) }€` }</span>
                    </div>
                    <div style={{ marginTop: '1rem', marginBottom: '1rem', display: "flex", alignItems: "center" }}>
                        <input className="custom-input-discout" type="text" name="giftcard" placeholder="Carte cadeau ou Code promo ?" value={this.state.giftCard} onChange={(evt) => this.setState({giftCard: evt.target.value})} />
                        <button className="selection-btn" style={{margin: 0, marginTop: 5, marginLeft: 15}} onClick={this.checkIfGiftCardIsValid}>Valider le code</button>
                        { this.state.giftCardAmount && <div className="gift-card-amount">{ `${ !this.state.isPercent ? (giftCardAmount / 100).toFixed(2) : -(this.state.giftCardAmount / 100).toFixed(2) }${ (this.state.isPercent) ? "%" : "€" }` }</div> }
                    </div>
                    <hr className="hr-recap" />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span className="sous-total bold">Total</span>
                        <span className="total">{(total / 100).toFixed(2)} €</span>
                    </div>
                </div>
                {/* item-part */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        basketList: state.basketList,
    };
}

export default connect(mapStateToProps)(RecapPage);
// export default RecapPage;
