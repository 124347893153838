import React from 'react';
import store from './redux/store'
import { Provider } from 'react-redux';
import Home from './pages/home/Home'
import Shop from './pages/shop/Shop'
import RecapPage from './pages/recap-page/RecapPage'
import ItemPage from './pages/item-page/ItemPage'
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import './App.css';
import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';
import Parse from 'parse';
import PackPage from './pages/pack-page/PackPage';
import ProfilingPage from './pages/profiling/ProfilingPage';
import TrakingPages from './pages/tracking-page/TrackingPage';
import StripeSuccess from './pages/stripe-success/StripeSuccess';
import GiftCardPage from './pages/gift-card/GiftCardPage';
import MobileSelection from './component/quiz/mobile/MobileSelection';
import Test from './pages/test/test';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import Close from '@material-ui/icons/Close';



Parse.serverURL = 'https://parseapi.back4app.com/';
Parse.initialize("QAg12WMZ0ZIORqF7iAIjCd2GliwizGVCU5yOrAvZ", "zTBq1RkEHsZ94h0js5rysCIIGUt1n3j7FKFI3dRY");

const NoMatchPage = () => {
  return (
    <div style={{ height: 300, textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      <h1><span style={{ color: "#5AC4C1", fontSize: 40 }}>404</span> - Not found</h1>
    </div>
  );
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inProfiling: false
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path='/' component={ProfilingPage} />
            /*<Route path='/shop' component={Shop} />
            <Route path='/item/:itemId' component={ItemPage} />
            <Route path='/pack' component={PackPage} />
            <Route path='/commande' component={RecapPage} />
            <Route path='/profiling' component={ProfilingPage} />
            <Route path='/giftcard' component={GiftCardPage} />
            <Route path='/tracking/:itemId' component={TrakingPages} />
            <Route path='/checkout/success' component={StripeSuccess} />*/
            <Route path='/test' component={Test} />
            <Route component={NoMatchPage} />
          </Switch>
          <Footer />
          <CookieConsent
            location="bottom"
            // ButtonComponent={Close}
            buttonText="X"
            cookieName="cookieConsent"
            extraCookieOptions={{ domain: "localhost:3001" }}
            style={{ background: "#2B373B", fontSize: "12px" }}
            buttonStyle={{ background: 'none', color: 'white' }}
            expires={30}
            onAccept={() => Cookies.set('cookieConsent', 'cookieConsent')}
          >
            En naviguant sur ce site, vous acceptez l’utilisation des cookies de Google Analytics. Les données sont des statistiques anonymes de visites collectées dans le but d’améliorer notre site web.
          </CookieConsent>
          {/* <Counter></Counter> */}
        </Router>
      </Provider>
    );
  }
}

export default withRouter(App);
