import React from 'react';
import addBasketWhite from '../../assets/home/add_basket_white.svg';
import './ShopCard.css';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

class ShopCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.item.id,
            name: this.props.item.get("Name"),
            price: this.props.item.get("PriceTTC"),
            image: this.props.item.get("Image1")._url
        }
    }

    componentDidMount() {
    }

    addBasket = () => {
        let basket = [];
        let retrievedData = localStorage.getItem('basket');
        if (retrievedData === null) {
            retrievedData = "[]"
        }
        basket = JSON.parse(retrievedData);
        basket.push(this.props.item);
        localStorage.setItem('basket', JSON.stringify(basket));
        this.props.dispatch({ type: 'ADD_TO_BASKET', item: this.props.item});
        this.props.click(this.props.item.get("Name"));
    };

    getQuantityInBasketForItem = (item) => {
        const basketString = localStorage.getItem('basket');
        if (!basketString) {
            return 0
        }
        const basket = JSON.parse(basketString);
        console.log(basket, item);
        return basket.filter(it => it.objectId === item.id).length;
    };

    render() {
        const {item} = this.props;
        const quantity = this.getQuantityInBasketForItem(item);

        console.log(quantity, item.get('Stock'));

        return (
                <div id={`scroll-shop-item-${item.id}`} className="shop-card">
                    <Link to={{ pathname: `/item/${item.id}`, state: { route: "/shop", name: "La boutique" } }} onClick={() => localStorage.setItem("shopItemSelected", item.id)}>
                        <div className="img-shop-card">
                            <img className="image-shopcard" src={item.get("Image1")._url}/>
                        </div>
                    <p>{item.get("Name")}</p>
                    </Link>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "90%" }}>
                            <span>{(item.get("PriceTTC") / 100).toFixed(2)} €</span>
                        </div>
                        { quantity < item.get('Stock') && <div className="btn-add-basket" onClick={this.addBasket}>
                            <img style={{ height: "18px", width: "18px" }} src={addBasketWhite} />
                        </div> }
                    </div>
                </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        basketList: state.basketList
    };
}

export default connect(mapStateToProps)(ShopCard);
