export const IN_PROFILING = 'IN_PROFILING';
export const OUT_PROFILING = 'OUT_PROFILING';
export const PUSH_ANWSER = 'PUSH_ANWSER';
export const GO_TO_ANWSER = 'GO_TO_ANWSER';
export const UPDATE_ANWSER = 'UPDATE_ANWSER';
export const UPDATE_DELETE_ANWSER = 'UPDATE_DELETE_ANWSER';
export const RESTART_QUIZ = 'RESTART_QUIZ';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const UPDATE_BASKET = "UPDATE_BASKET";
export const ADD_NEW_TAGS = "ADD_NEW_TAGS";
export const UPDATE_PACKAGE = "UPDATE_PACKAGE";