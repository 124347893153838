import React from 'react';
import './Basket.css';
// import basketWhite from '../../assets/home/add_basket_white.svg';
import btnBasket from '../../assets/home/btn_profile.svg';
import addBasketWhite from '../../assets/shop/basket_opacity.svg'
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';


class Basket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basket: [],
            basketList: [],
            quantities: {},
        }
    }

    componentDidMount() {
        this.props.dispatch({type: "UPDATE_BASKET"});
    }

    componentWillReceiveProps(nextProps) {
      const quantities = nextProps.basketList.reduce((qtts, item) => {
        qtts[item.objectId] = qtts[item.objectId] ? qtts[item.objectId] + 1 : 1 ;

        return qtts;
      }, {});

      this.setState({basketList: nextProps.basketList, quantities});
    }

    toggleDrawer = (anchor, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        this.setState({ anchor: open });
    };

    getBasket() {
        let retrievedDataBasket = localStorage.getItem('basket') || [];
        if (retrievedDataBasket.length > 0) {
            this.setState({ basket: JSON.parse(retrievedDataBasket) });
            return JSON.parse(retrievedDataBasket)
        }
        return []
    }

    list = (anchor, basketList, deliveryPrice, totalPrice, giftCardsValue) => {
      let i = -1;

      return (
          <div
              role="presentation"
              onKeyDown={this.toggleDrawer(anchor, false)}
              className="basket-drawer"
          >
              <h2>Votre panier</h2>
              {
                          basketList.length ? <div>
                              <div className="item-list-basket">
                                  {
                                      basketList.map((item) => {
                                        if (item.IsGiftCard) {
                                          i = i + 1;
                                        }

                                        return (
                                          <>
                                              <div className="item-basket">
                                                  <div className="item-basket-img-box" style={{ width: '30%', marginRight: '5px' }}>
                                                      {item.Image1 ? <img className="item-basket-img-box" src={item.Image1.url}/> : <></> }
                                                      <span className="delete-item-basket" onClick={() => this.deleteBasket(item, giftCardsValue[i])}>Supprimer</span>
                                                  </div>
                                                  <div style={{ width: '70%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                      <span className="title-item-basket">{item.Name}</span>
                                                      <div style={{ textAlign: 'right' }}>
                                                          <span className="price-item-basket">{(item.IsGiftCard) ? (giftCardsValue[i] / 100).toFixed(2) : (item.PriceTTC * this.state.quantities[item.objectId] / 100).toFixed(2)} €</span>
                                                      </div>
                                                      {!item.IsGiftCard && <div style={{ alignItems: "center", display: "flex"}}>
                                                        <IconButton onClick={ () => this.handleQuantity(item, "remove") } size="small" style={{ color: "white", cursor: "pointer", marginRight: "5px"}}>
                                                          <RemoveIcon />
                                                        </IconButton>
                                                        <span className="nbr-of-item">Quantité: { this.state.quantities[item.objectId] }</span>
                                                        <IconButton onClick={ () => this.handleQuantity(item, "add") } size="small" style={{ color: "white", cursor: "pointer", marginLeft: "5px"}}>
                                                          <AddIcon />
                                                        </IconButton>
                                                      </div>}
                                                  </div>
                                              </div>
                                              <hr className="hr-item-basket" />
                                          </>
                                        )
                                      })
                                  }
                              </div>
                              <div>
                                  <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                      <span className='price-detail'>Total</span>
                                      <span className='price-basket'>{(totalPrice / 100).toFixed(2)} €</span>
                                  </div>
                                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "7px" }}>
                                      <span className='price-detail'>Livraison</span>
                                      <span className='price-basket'>{ (deliveryPrice / 100).toFixed(2) } €</span>
                                  </div>
                                  <div className='total-box' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                      <span className='price-basket'>Total TTC</span>
                                      <span className='price-basket'>{((totalPrice + deliveryPrice) / 100).toFixed(2)} €</span>
                                  </div>
                                  <Link to={'/commande'}><button className="selection-btn" style={{ marginLeft: "1rem" }}>Finaliser la commande</button></Link>
                              </div>
                          </div> :
                              <div style={{ marginTop: "14rem" }}>
                                  <img src={addBasketWhite} />
                                  <p>Votre panier est vide</p>
                              </div>
                      }
          </div>
      )
    };

    onStartQuiz = () => {
        this.props.dispatch({ type: 'IN_PROFILING' });
    }

    deleteBasket = (item, giftCardValue) => {
        let basket = this.getBasket();

        if (item.IsGiftCard) {
          let retrievedData = localStorage.getItem("giftCardsValue");
          if (retrievedData === null) {
            retrievedData = "[]";
          }

          let giftCardsValue = JSON.parse(retrievedData);
          let indexOfValue = giftCardsValue.findIndex((v) => v === giftCardValue);
          giftCardsValue = giftCardsValue.filter((v, index) => index !== indexOfValue);
          localStorage.setItem("giftCardsValue", JSON.stringify(giftCardsValue));

          let indexOfItem = basket.findIndex((v) => v.objectId === item.objectId);
          basket = basket.filter((v, index) => index !== indexOfItem);
          localStorage.setItem("basket", JSON.stringify(basket));
          this.props.dispatch({ type: "UPDATE_BASKET", item: item});
        } else {
          basket = basket.filter(v => v.objectId !== item.objectId);
          localStorage.setItem('basket', JSON.stringify(basket));
          this.props.dispatch({ type: 'UPDATE_BASKET', item: item});
          this.props.onBasketUpdate();
        }
    }

    totalPrice = () => {
        const { basketList } = this.state
        if (basketList.length) {
            basketList.map((item) => item.PriceTTC * 1)
            let itemsPrice = 0
            for (let i = 0; i < basketList.length; i++) {
                itemsPrice = itemsPrice + basketList[i].PriceTTC * 1;
            }

            let retrievedData = localStorage.getItem("giftCardsValue");
            if (retrievedData === null) {
              retrievedData = "[]";
            }

            const giftCardsValue = JSON.parse(retrievedData);

            for (let i = 0; i < giftCardsValue.length; i++) {
              itemsPrice = itemsPrice + giftCardsValue[i];
            }

            return itemsPrice
        }

        return 0
    }

    getDeliveryPrice(totalPrice) {
      const basket = this.state.basketList.filter((item) => !item.IsGiftCard);

      if (basket.length === 0)
        return 0;

      let totalDelivery = 0;

      let itemsNumber = 0;

      basket.forEach((product) => {
        if (product.Pack) {
          totalDelivery = totalDelivery + 1199;
        } else {
          itemsNumber++;
        }
      });

      if (itemsNumber >= 6) {
        return totalDelivery + 1199;
      } else if (itemsNumber >= 4) {
        return totalDelivery + 999;
      } else if (itemsNumber >= 2) {
        return totalDelivery + 499;
      } else {
        return totalDelivery + 299;
      }

      return totalDelivery;
    }

    handleQuantity(item, type) {
      let basket = [];

      let retrievedDataBasket = localStorage.getItem('basket') || [];
      if (retrievedDataBasket.length > 0) {
          basket = JSON.parse(retrievedDataBasket);
      }

      if (type === "add") {
        basket.push(item);
        localStorage.setItem("basket", JSON.stringify(basket));
        this.props.dispatch({ type: "ADD_TO_BASKET", item });
      } else {
        let indexOfItem = basket.findIndex((v) => v.objectId === item.objectId);

        basket = basket.filter((v, index) => index !== indexOfItem);
        localStorage.setItem("basket", JSON.stringify(basket));
        this.props.dispatch({ type: "UPDATE_BASKET", item: item});
      }
    }

    render() {
        let anchor = 'right';
        let { basketList } = this.state;

        basketList = basketList.reduce((bl, item) => {
          if (!item.IsGiftCard && bl.find((i) => i.objectId === item.objectId)) {
            return bl;
          } else {
            bl.push(item);
            return bl;
          }
        }, []);

        let retrievedData = localStorage.getItem("giftCardsValue");
        if (retrievedData === null) {
          retrievedData = "[]";
        }

        const giftCardsValue = JSON.parse(retrievedData);

        let i = -1;

        let withSize = this.props.width < 1100 ? "0%" : "22%"

        const totalPrice = this.totalPrice();
        const deliveryPrice = this.getDeliveryPrice(totalPrice);

        return (
            <>
                {/* basket */}
                <div hidden={this.props.width < 1100} className="basket" style={{ width: withSize, padding: "1rem", maxWidth: withSize, position: "fixed", right: "2%", top: "15%"}}>
                    <h2>Votre panier</h2> {
                        basketList.length ? <div>
                            <div className="item-list-basket">
                                {
                                    basketList.map((item) => {
                                      if (item.IsGiftCard) {
                                        i = i + 1;
                                      }

                                      return (
                                        <>
                                            <div className="item-basket">
                                                <div className="item-basket-img-box" style={{ width: '30%', marginRight: '5px' }}>
                                                    {
                                                      item.Image1 ? <img className="item-basket-img-box" src={item.Image1.url} /> : <></>
                                                    }
                                                    <span className="delete-item-basket" onClick={() => this.deleteBasket(item, giftCardsValue[i])}>Supprimer</span>
                                                </div>
                                                <div style={{ width: '70%', display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                                    <span className="title-item-basket">{item.Name}</span>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <span className="price-item-basket">{(item.IsGiftCard) ? (giftCardsValue[i] / 100).toFixed(2) : (item.PriceTTC * this.state.quantities[item.objectId] / 100).toFixed(2)} €</span>
                                                    </div>
                                                    {!item.IsGiftCard && <div style={{ alignItems: "center", display: "flex"}}>
                                                      <IconButton onClick={ () => this.handleQuantity(item, "remove") } size="small" style={{ color: "white", cursor: "pointer", marginRight: "5px"}}>
                                                        <RemoveIcon />
                                                      </IconButton>
                                                      <span className="nbr-of-item">Quantité: { this.state.quantities[item.objectId] }</span>
                                                        { this.state.quantities[item.objectId] < item.Stock && <IconButton onClick={ () => this.handleQuantity(item, "add") } size="small" style={{ color: "white", cursor: "pointer", marginLeft: "5px"}}>
                                                        <AddIcon />
                                                      </IconButton> }
                                                    </div>}
                                                </div>
                                            </div>
                                            <hr className="hr-item-basket" />
                                        </>
                                      )
                                    })
                                }
                            </div>
                            <div style={{ marginTop: "10px" }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span className='price-detail'>Total</span>
                                    <span className='price-basket'>{(totalPrice / 100).toFixed(2)} €</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "7px" }}>
                                    <span className='price-detail'>Livraison</span>
                                    <span className='price-basket'>{ (deliveryPrice / 100).toFixed(2) } €</span>
                                </div>
                                <div className='total-box' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span className='price-basket'>Total TTC</span>
                                    <span className='price-basket'>{((totalPrice + deliveryPrice) / 100).toFixed(2)} €</span>
                                </div>
                                <Link to={'/commande'}><button className="selection-btn" style={{ marginLeft: "1rem" }}>Finaliser la commande</button></Link>
                            </div>
                        </div> :
                            <div style={{ marginTop: "14rem" }}>
                                <img src={addBasketWhite} />
                                <p>Votre panier est vide</p>
                            </div>
                    }
                </div>
                {/* basket */}


                {/* hidden basket */}

                <div hidden={this.props.width > 1100}>
                    <React.Fragment key={anchor}>
                        <div style={{height: "40px", position: "fixed", top: "200px", right: "0px" }} onClick={this.toggleDrawer(anchor, true)}>
                            <img style={{ height: "67px", width: "35px", marginTop: "10px" }} src={btnBasket} />
                        </div>
                        <Drawer anchor={anchor} open={this.state.anchor} onClose={this.toggleDrawer(anchor, false)}>
                            {this.list(anchor, basketList, deliveryPrice, totalPrice, giftCardsValue)}
                        </Drawer>
                    </React.Fragment>
                </div>

                {/* hidden basket */}
            </>)
    }
}

const mapStateToProps = (state) => {
    return {
        basketList: state.basketList,
    };
}

export default connect(mapStateToProps)(Basket);
