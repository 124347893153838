import React, { useState, useEffect } from 'react';
import { Flex } from 'react-flex-material';
import illustrationBackgroundHomepageMobile from '../../assets/home/illustration_background_homepage copy.png'
import Schema from '../../assets/home/schema.svg';
import iconLight from '../../assets/home/icon_light.svg';
import { Link } from 'react-router-dom';
import ButtonReadyPack from '../../assets/home/button_ready_pack.svg';
import iconPackPersonalize from '../../assets/home/icon_pack_personalize.svg';
import ButtonPersonalizePack from '../../assets/home/button_personalize_pack.svg';
import ApostropheLeft from '../../assets/home/apostrophe_left.svg';
import ApostropheRight from '../../assets/home/apostrophe_right.svg';
import ButtonJournal from '../../assets/home/button_journal.svg';
import BgUs from '../../assets/home/bg_white_us.svg';
import Carousel from '@brainhubeu/react-carousel';
import arrowPrevious from '../../assets/home/arrow_previous.svg';
import arrowNext from '../../assets/home/arrow_next.svg';
import BgMockupApp from '../../assets/home/bg_mockup_app.png';
import ButtonDownload from '../../assets/home/button_download.svg';
import mockupLJSM from '../../assets/home/mockup_ljsm.png';
import Parse from 'parse';
import sendButton from '../../assets/home/send_button.svg'
import MuiAlert from '@material-ui/lab/Alert';
import '@brainhubeu/react-carousel/lib/style.css';
import Snackbar from '@material-ui/core/Snackbar';
import Favorites from '../../component/favorites/Favorites';
import Twins from '../../assets/home/ljsm.jpg'
import StartButton from '../../assets/home/start_button.svg'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const HomeMobile = ({ width }) => {

    let [isOpen, setIsOpen] = React.useState(false);
    const [{ username, email, message, acceptUserOfInformation }, setState] = useState({
        username: '',
        email: '',
        message: '',
        acceptUserOfInformation: false,
    });

    const handleInputChange = (target, value) => {
        setState((pinputs) => ({
            ...pinputs,
            [target]: value,
        }));
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsOpen(false);
    }

    const handleClick = () => {
        setIsOpen(true);
    };

    const sendContactUsMail = async (contactId) => {
        const response = await Parse.Cloud.run("emailcheckout", {
            contactId: contactId
        });
    }

    const mySubmitHandler = (event) => {
        event.preventDefault();
        if (email !== '' && message !== '') {
            var ContactUs = Parse.Object.extend("ContactUs");
            var contactUs = new ContactUs();

            contactUs.save({
                Email: email,
                Username: username,
                Content: message
            })
                .then((contactUs) => {
                    handleClick();
                    localStorage.setItem('user', JSON.stringify({ username: username, email: email }));
                    setState({ username: '', email: '', message: '' });
                    sendContactUsMail(contactUs.id);
                }, (error) => {
                });
        }
    }

    return (
        <>
            <Flex layout="column">
                <img src={illustrationBackgroundHomepageMobile} style={{ maxWidth: '100%', objectPosition: 'center', objectFit: 'contain', position: 'absolute' }} />
                <Flex layout="column" align="center center" style={{ position: 'relative', height: '300px', paddingRight: '15%', paddingLeft: '3rem', paddingTop: '38%' }}>
                    <Flex layout="column" style={{ paddingRight: '3rem' }}>
                        <span className="text-presentation" style={{ fontSize: '1.8em' }}>En 5min  trouvez la déco qui vous ressemble</span>
                        <p className="description-presentation-mobile" style={{ fontSize: '0.8em' }}>En manque d'idées pour relooker une pièce? Les jumelles s'en mêlent vous proposent une sélection d'accessoires déco adaptés à votre profil. Et vous quel est votre profil?</p>
                        <Link to={'/profiling'}><img style={{ width: '150px' }} src={StartButton} /></Link>
                    </Flex>
                </Flex>
                <Flex layout="column" align="center center" style={{ marginTop: '6rem', marginBottom: '1rem' }}>
                    <img style={{ width: "90%", padding: '1rem' }} src={Schema} />
                    <Flex layout="row" align="start start" style={{ width: '100%', textAlign: 'center' }}>
                        <Flex className="text-shema">
                            <span className="number-step">01</span>
                            <p className="description-step" style={{ fontSize: '0.8em', opacity: '0.52', paddingRight: "10px", paddingLeft: "10px" }}>Je définis mon profil</p>
                        </Flex>
                        <Flex className="text-shema">
                            <span className="number-step">02</span>
                            <p className="description-step" style={{ fontSize: '0.8em', opacity: '0.52', paddingRight: "10px", paddingLeft: "10px" }}>Je découvre le pack déco qui correspond à mon profil</p>
                        </Flex>
                        <div className="text-shema">
                            <span className="number-step">03</span>
                            <p className="description-step" style={{ fontSize: '0.8em', opacity: '0.52', paddingRight: "10px", paddingLeft: "10px" }}>Je personnalise et je commande mon pack</p>
                        </div>
                        <div className="text-shema">
                            <span className="number-step">04</span>
                            <p className="description-step" style={{ fontSize: '0.8em', opacity: '0.52', paddingRight: "10px", paddingLeft: "10px" }}>Livraison à domicile avec carnet personnalisé d'installation</p>
                        </div>
                    </Flex>
                </Flex>
                <video width="100%" height="60%" controls>
                    <source src="https://s3.eu-west-3.amazonaws.com/app.lesjumelles.public/intro_ljsm_youtube.mov" type="video/mp4" />
                    Your browser does not support the video tag or the file format of this video. <a href="http://www.supportduweb.com/">http://www.supportduweb.com/</a>
                </video>
                <Flex layout="column" align="start center" style={{ marginTop: '1rem' }}>
                    <span className="journal-title" style={{ marginTop: '1rem', fontSize: '1.5em' }}>Découvrez nos packs déco</span>
                    <Flex layout="column" align="start center" style={{ marginTop: '2rem' }}>
                        <Flex layout="column" align="start center" style={{ textAlign: 'center', padding: "1rem 2rem" }}>
                            <img src={iconLight} />
                            <span style={{ marginTop: '1rem' }}>Besoin d'idées?</span>
                            <span style={{ marginTop: '1rem', opacity: '0.52', color: '#041212' }}>Voici nos jolis Packs déco tendance, pensés par des architectes d’intérieur Prêts à installer</span>
                            <Link to={'/pack'} style={{ marginTop: '1rem', zoom: 0.8 }}><img src={ButtonReadyPack} /></Link>
                        </Flex>
                        <Flex layout="column" align="start center" style={{ textAlign: 'center', marginTop: '4rem', padding: "1rem 2rem" }}>
                            <img src={iconPackPersonalize} />
                            <span style={{ marginTop: '1rem' }}>Personnalisez</span>
                            <span style={{ marginTop: '1rem', opacity: '0.52', color: '#041212' }}>Composez vous-même votre déco à partir des styles proposés dans la bout</span>
                            <Link to={'/shop'} style={{ marginTop: '1rem', zoom: 0.8 }}><img src={ButtonPersonalizePack} /></Link>
                        </Flex>
                        <Flex layout="column" align="center center" style={{ padding: '1rem', marginBottom: '2rem', marginTop: '2rem' }}>
                            <Flex layout style={{ width: '100%' }} align="start">
                                <img src={ApostropheLeft} />
                            </Flex>
                            <span style={{ textAlign: 'center', fontSize: '0.8em', padding: '0.5rem 2rem', opacity: '0.52' }}>Nous sommes là pour vous faciliter la mise en oeuvre, vous donner des idées d'installation et surtout nos conseils de pro.</span>
                            <Flex layout style={{ width: '100%' }} align="end">
                                <img src={ApostropheRight} />
                            </Flex>
                        </Flex>

                    </Flex>
                </Flex>
                <Flex layout="column" align="start center" style={{ padding: '1rem 2rem', marginTop: '2rem', textAlign: 'center' }}>
                    <Flex layout="column" align="start center">
                        <span className="journal-title" style={{ marginTop: '1rem', fontSize: '1.5em' }}>Découvrez nos coups de coeur</span>
                        <span style={{ marginTop: '0.5rem', opacity: '0.52', color: '#041212', fontSize: '0.8rem' }}>Une sélection de pépites à la carte, repérage d’initiés, produits uniques ou limités, issu des plus belles marques, de l’artisanat ou du design, ou simplement les must have à avoir pour être dans les tendances actuelles.</span>
                    </Flex>
                    <Favorites width={width} />
                </Flex>

                <Flex layout="column" align="start center" style={{ textAlign: 'center' }}>
                    <span className="title-us" style={{ fontSize: '1.5em', marginTop: '1rem' }}>Qui sommes-nous?</span>
                    <Flex layout="column" align="start center" style={{ padding: '1rem' }}>
                        <img style={{ display: 'inline', objectFit: 'contain', width: '25%' }} src={Twins} />
                    </Flex>
                    <Flex layout="column" align="start center" style={{ padding: '0 2rem 1rem' }}>
                        <span style={{ fontSize: '0.8em', marginTop: '1rem', opacity: '0.52', color: '#041212' }}>A la tête de cette aventure, nous sommes 2 sœurs jumelles, Laure Architecte d’intérieur et Séverine Décoratrice et Artiste peintre. Toutes les deux passionnées par la décoration, le design et les couleurs.</span>
                        <span style={{ fontSize: '0.8em', marginTop: '1rem', opacity: '0.52', color: '#041212' }}>Notre force, c’est notre complémentarité. Infatigables d’idées nouvelles, d’originalité et de bonne humeur ! Souvent sollicitées pour des projets d’aménagements intérieurs hauts de gamme, nous avons souhaité donner accès à notre sélection de produits phares tout en proposant une expérience unique, facile et innovante. </span>
                        <span style={{ fontSize: '0.8em', marginTop: '1rem', opacity: '0.52', color: '#041212' }}>Les Jumelles s'en mêlent : un concept boutique innovant et engagé.  Soucieuses également d’élargir notre gamme vers des produits moins impactant sur l'environnement et plus éthiques, nous avons eu l'envie d’être une jolie vitrine pour une sélection d’artisans, designers ou fabricants alliant le beau et le côté éco-responsable. Persuadées que notre façon de décorer doit changer au profit d’intérieurs plus en harmonie avec nos nouveaux modes de vies et nos aspirations.</span>
                    </Flex>
                </Flex>

                <Flex layout="column" className="journal" style={{ marginTop: '4rem', marginBottom: '4rem', padding: '1rem 2rem' }}>
                    <span className="journal-title" style={{ marginTop: '1rem', fontSize: '1.5em' }}>Le journal de la déco</span>
                    <span style={{ marginTop: '1rem', fontSize: '0.8em', opacity: '0.52', color: '#041212' }}>Découvrez les tendances déco, nos bons plans et astuces, des DiY et des articles, les <br /> évènements à ne pas louper,…</span>
                    <img style={{ marginTop: '1rem', zoom: '0.8' }} src={ButtonJournal} />
                </Flex>
                <Flex style={{ padding: '0 1rem', marginTop: '2rem' }}>
                    <Carousel
                        arrowLeft={<img style={{ zoom: 0.8 }} src={arrowPrevious} />}
                        arrowRight={<img style={{ zoom: 0.8 }} src={arrowNext} />}
                        slidesPerPage={1}
                        infinite
                        dots
                        addArrowClickHandler
                    >
                        <div className="feedback-wrap">
                            <span><strong>Aurélie</strong>, 40ans</span>
                            <p className="feedback-wrap-mobil">L'ensemble de déco que les Jumelles m'ont proposé correspond parfaitement à mes attentes, les articles sont de qualité, le rendu est très professionnel, j'ai beaucoup aimé les petites idées déco glissées dans mon carton et le livret facilitant l'installation des accessoires</p>
                        </div>

                        <div className="feedback-wrap">
                            <span><strong>Anne-laure</strong>, 35ans</span>
                            <p className="feedback-wrap-mobil">La livraison à domicile du pack déco m'a permis de gagner un temps précieux ! Je suis très contente du résultat</p>
                        </div>

                    </Carousel>
                </Flex>
                <Flex layout="column" align="start center" style={{ backgroundImage: `url(${BgMockupApp})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat', marginTop: '1rem', textAlign: 'center', padding: "1rem 2rem" }}>
                    <span className="title-mockup" style={{ fontSize: '1.5em', marginTop: '2rem' }}>Bientôt l’App !</span>
                    <span style={{ marginTop: '1rem' }}>Jouer à être votre propre décorateur</span>
                    <span style={{ marginTop: '1rem' }}>Inscrivez-vous à la newsletter pour être les 1ers informés</span>
                    <a href="https://lesjumellessenmelent.fr/blog-les-jumelles-sen-melent/#newsletter"><img style={{ zoom: 0.8, marginTop: '1rem' }} src={ButtonDownload} /></a>
                    <img style={{ zoom: 0.5, marginTop: '2rem' }} src={mockupLJSM} />
                </Flex>
                <div className="wrap-contact">
                    <h2 style={{ fontSize: '1.5em', marginTop: '2rem' }}>Nous contacter</h2>
                    <form onSubmit={mySubmitHandler} className="contact-form-mobile">
                        <input onChange={(event) => handleInputChange("username", event.target.value)} value={username} placeholder="Nom" type="text" name="username" />
                        <input onChange={(event) => handleInputChange("email", event.target.value)} value={email} placeholder="Email" type="email" name="email" />
                        <textarea onChange={(event) => handleInputChange("message", event.target.value)} value={message} placeholder="Votre message" type="text" name="message" />
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "flex-start", marginBottom: "3rem", width: "100%" }}>
                            <input style={{ marginTop: "18px", minWidth: 0, minHeight: 0, width: "10%" }} type="checkbox" onChange={(event) => handleInputChange("acceptUserOfInformation", event.target.value)} value={acceptUserOfInformation} id="acceptUserOfInformation" name="acceptUserOfInformation" required />
                            <label className="text-us" for="acceptUserOfInformation" >J’autorise ce site à conserver mes données transmises via ce formulaire</label>
                        </div>
                        <button style={{ border: '0', backgroundColor: "transparent" }} type="submit"><img src={sendButton} /></button>
                    </form>
                </div>
                <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Votre message a bien été envoyé.
                    </Alert>
                </Snackbar>
            </Flex>
        </>
    )
};
