import React from 'react';
import './TrackingPage.css';
import logoLJSM from '../../assets/navbar/logo_rounded.svg'
import bgTracking from '../../assets/tracking/bg-tracking.png'
import checkGreen from '../../assets/tracking/check_green.svg'
import checkGrey from '../../assets/tracking/check_grey.svg'
import check from '../../assets/tracking/check.svg'
import deliveryCheckGreen from '../../assets/tracking/delivery_check_green.svg'
import deliveryCheckGrey from '../../assets/tracking/delivery_check_grey.svg'
import deliveryCheck from '../../assets/tracking/delivery_check.svg'
import deliveryGreen from '../../assets/tracking/delivery_green.svg'
import deliveryGrey from '../../assets/tracking/delivery_grey.svg'
import delivery from '../../assets/tracking/delivery.svg'
import orderGreen from '../../assets/tracking/order_green.svg'
import orderGrey from '../../assets/tracking/order_grey.svg'

import Parse from 'parse';
import Moment from 'moment';

class TrakingPages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: {},
            style: {
                imgStepOne: checkGrey, colorOne: "#E6E7E8", colorLineOne: "#E6E7E8",
                imgStepTwo: checkGrey, colorTwo: "#E6E7E8", colorLineTwo: "#E6E7E8",
                imgStepThree: checkGrey, colorThree: "#E6E7E8", colorLineThree: "#E6E7E8",
                imgStepFour: checkGrey, colorFour: "#E6E7E8", colorLineFour: "#E6E7E8",
            }
        }
    }

    async componentDidMount() {
        let Product = Parse.Object.extend("Order");
        const query = new Parse.Query(Product);
        query.equalTo("objectId", this.props.match.params.itemId);
        const results = await query.first();
        let style = this.updateStylePage(results.get("Statut"))
        this.setState({ order: {id: results.id, date: results.get("createdAt"), statut: results.get("Statut")}, style: style})
    }

    updateStylePage(statut) {
        let pageStyle = {}
        switch (statut) {
            case 0:
            return pageStyle = {
                    imgStepOne: checkGreen, colorTextOne: "#5ac4c1", colorLineOne: "#E6E7E8",
                    imgStepTwo: orderGrey , colorTextTwo: "#E6E7E8", colorLineTwo: "#E6E7E8",
                    imgStepThree: deliveryGrey, colorTextThree: "#E6E7E8", colorLineThree: "#E6E7E8",
                    imgStepFour: deliveryCheckGrey, colorTextFour: "#E6E7E8", colorLineFour: "#E6E7E8",
                };
            case 1:
            return pageStyle = {
                    imgStepOne: checkGrey, colorTextOne: "#808285", colorLineOne: "#808285",
                    imgStepTwo: orderGreen , colorTextTwo: "#5ac4c1", colorLineTwo: "#E6E7E8",
                    imgStepThree: deliveryGrey, colorTextThree: "#E6E7E8", colorLineThree: "#E6E7E8",
                    imgStepFour: deliveryCheckGrey, colorTextFour: "#E6E7E8", colorLineFour: "#E6E7E8",
                };
            case 2:
            return pageStyle = {
                    imgStepOne: checkGrey, colorTextOne: "#808285", colorLineOne: "#808285",
                    imgStepTwo: orderGrey , colorTextTwo: "#808285", colorLineTwo: "#808285",
                    imgStepThree: deliveryGreen, colorTextThree: "#5ac4c1", colorLineThree: "#E6E7E8",
                    imgStepFour: deliveryCheckGrey, colorTextFour: "#E6E7E8", colorLineFour: "#E6E7E8",
                };
            case 3:
            return pageStyle = {
                    imgStepOne: checkGrey, colorTextOne: "#808285", colorLineOne: "#808285",
                    imgStepTwo: orderGrey, colorTextTwo: "#808285", colorLineTwo: "#808285",
                    imgStepThree: deliveryGrey, colorTextThree: "#808285", colorLineThree: "#808285",
                    imgStepFour: deliveryCheckGreen, colorTextFour: "#5ac4c1", colorLineFour: "#E6E7E8",
                };
            default:
                break;
        }
    }




    render() {
        const {order, style} = this.state;
        Moment.locale('fr');
        var dt = this.state.order.date;
        return (
            <div style={{
                backgroundImage: `url(${bgTracking})`,
                height: "650px",
                backgroundSize: "cover",
                backgroundRepeat: 'no-repeat',
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: '5rem',
                paddingBottom: '5rem'
            }}>
                <div style={{width: "100%", marginRight: "10%",
                marginLeft: "10%"}}>
                    <div style={{textAlign: 'center'}}>
                        <img src={logoLJSM}/>
                        <h1 className={'title-tracking'}>Les jumelles s'en mêlent</h1>
                    </div>
                    <div className="tracking-card">
                        <h2 className="title-card-track">Votre suivi,</h2>
                        <h3 className="commande-number">Votre commande N°{this.state.order.id} (datant du {Moment(dt).format('dddd, D MMMM YYYY LT')})</h3>
                        <div style={{display: "flex"}}>
                            <div style={{textAlign: 'center', width: '10%'}}>
                                <img src={style.imgStepOne || check}/>
                                <p style={{color: `${style.colorTextOne}`}} className="step-title">Commande en préparation</p>
                            </div>
                            <div className="step-line" style={{backgroundColor: `${style.colorLineOne}`}}></div>
                            <div style={{textAlign: 'center', width: '10%'}}>
                                <img src={style.imgStepTwo}/>
                                <p style={{color: `${style.colorTextTwo}`}} className="step-title">Commande prête à l'expedition</p>
                            </div>
                            <div className="step-line" style={{backgroundColor: `${style.colorLineTwo}`}}></div>
                            <div style={{textAlign: 'center', width: '10%'}}>
                                <img src={style.imgStepThree}/>
                                <p style={{color: `${style.colorTextThree}`}} className="step-title">En cours de livraison</p>
                            </div>
                            <div className="step-line" style={{backgroundColor: `${style.colorLineThree}`}}></div>
                            <div style={{textAlign: 'center', width: '10%'}}>
                                <img src={style.imgStepFour}/>
                                <p style={{color: `${style.colorTextFour}`}} className="step-title">Livré</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TrakingPages;
