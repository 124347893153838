import React from 'react';
import './ItemPage.css';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import addBasketWhite from '../../assets/home/add_basket_white.svg'
import Parse from 'parse';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter} from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class MyCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      slides: [],
      thumbnails: [],
    }
    this.onchange = this.onchange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let new_slides = [];
    let new_thumbnails = [];

    if (nextProps.imageList.Image1) {
      new_slides.push((<img className="image-custom" src={nextProps.imageList.Image1} />))
      new_thumbnails.push((<img className="image-slide" src={nextProps.imageList.Image1} />))
    } if (nextProps.imageList.Image2) {
      new_slides.push((<img className="image-custom" src={nextProps.imageList.Image2} />))
      new_thumbnails.push((<img className="image-slide" src={nextProps.imageList.Image2} />))
    } if (nextProps.imageList.Image3) {
      new_slides.push((<img className="image-custom" src={nextProps.imageList.Image3} />))
      new_thumbnails.push((<img className="image-slide" src={nextProps.imageList.Image3} />))
    } if (nextProps.imageList.Image4) {
      new_slides.push((<img className="image-custom" src={nextProps.imageList.Image4} />))
      new_thumbnails.push((<img className="image-slide" src={nextProps.imageList.Image4} />))
    } if (nextProps.imageList.Image5) {
      new_slides.push((<img className="image-custom" src={nextProps.imageList.Image5} />))
      new_thumbnails.push((<img className="image-slide" src={nextProps.imageList.Image5} />))
    }


    this.setState({ slides: new_slides, thumbnails: new_thumbnails });
  }

  onchange(value) {
    this.setState({ value });
  }

  render() {
    var thumbnails = document.getElementsByClassName("image-slide");

    for (let i = 0; i < thumbnails.length; i++) {
      if (this.state.value === i) {
        thumbnails[i].style.border = "2px solid #5ac4c1";
      } else {
        thumbnails[i].style.border = "none";
      }
    }

    return (
      <>
      <div className="carousel-responsive-size">
        <Carousel
          value={this.state.value}
          slides={this.state.slides}
          onChange={this.onchange}
          slidesPerPage={1}
        />
        <div style={{ marginTop: "2rem" }}>
          <Dots number={this.state.thumbnails.length} thumbnails={this.state.thumbnails} value={this.state.value} onChange={this.onchange} number={this.state.slides.length} />
        </div>
      </div>
      </>
    );
  }
}


class ItemPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      open: false,
      onStock: true,
      route: "",
      nameBack: "",

      shouldScrollTop: false,
    }
  }

  async componentDidMount() {
    let Product = Parse.Object.extend("Product");
    const query = new Parse.Query(Product);
    query.equalTo("objectId", this.props.match.params.itemId);
    const results = await query.first();
    if (!results) {
      return (this.props.history.push('/404'));
    }
    this.setState({ item: results })
    this.checkStock(results.get("Stock"))
    if (this.props.history.location.state) {
      this.setState({route: this.props.history.location.state.route, nameBack: this.props.history.location.state.name});
    }
  }

  componentDidUpdate() {
    if (this.state.shouldScrollTop) {
      document.getElementById("scroll-top").scrollIntoView(true);
      this.setState({shouldScrollTop: false});
    }
  }

  addBasket = () => {
    let basket = [];
    let retrievedData = localStorage.getItem('basket');
    if (retrievedData === null) {
      retrievedData = "[]"
    }
    this.handleClick();
    basket = JSON.parse(retrievedData);
    basket.push(this.state.item);
    localStorage.setItem('basket', JSON.stringify(basket));
    this.props.dispatch({ type: 'ADD_TO_BASKET', item: this.props.item });
  }

  setImageList() {
    let imageList = this.state.item && {
      Image1: this.state.item.get("Image1")._url,
      Image2: this.state.item.get("Image2") && this.state.item.get("Image2")._url,
      Image3: this.state.item.get("Image3") && this.state.item.get("Image3")._url,
      Image4: this.state.item.get("Image4") && this.state.item.get("Image4")._url,
      Image5: this.state.item.get("Image5") && this.state.item.get("Image5")._url
    }
    return imageList
  }

  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  checkStock = (stock) => {
    if (stock > 0) {
      this.setState({onStock: true, shouldScrollTop: true})
    } else {
      this.setState({onStock: false, shouldScrollTop: true})
    }
  }

  getQuantityInBasketForItem = (item) => {
    const basket = this.props.basketList;
    if (!basket) {
      return 0;
    }
    return basket.filter(it => it.objectId === item.id).length;
  };

  render() {
    let image = this.state.item && this.state.item.get("Image1")._url;
    let imageList = this.state.item && this.setImageList();
    if (!this.state.item) {
      return <></>
    }
    const quantity = this.getQuantityInBasketForItem(this.state.item);
    return (
      <div className="item-page">
        <span className="path" style={{ alignItems: "center", display: "flex"}}>
          <Link to={this.state.route}>
            <div className="back-button">{ this.state.nameBack }
            </div>
          </Link>
    > { this.state.item ? <div className="back-button-dot">{this.state.item.get("Name")}</div> : ""}
        </span>
        <div className="container-item-page">
          <div className="container-left-ip">
            <MyCarousel imageList={imageList}>
            </MyCarousel>
          </div>
          <div className="container-right-ip">
            <h1>{this.state.item && this.state.item.get("Name")}</h1>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "4rem" }}>
              {
                this.state.onStock && quantity < this.state.item.get('Stock') ? <div  onClick={this.addBasket} className="backet">
                <img src={addBasketWhite} />
                </div> : <div className="backet-disable">
                  <img src={addBasketWhite} />
                </div>
              }
              <span className="price">{this.state.item && (this.state.item.get("PriceTTC") / 100).toFixed(2)} €</span>
            </div>
            <span className="error" hidden={this.state.onStock}>En rupture de stock</span>
            <p style={{whiteSpace: "pre-wrap", fontSize: 17}}>{this.state.item && this.state.item.get("Description")}</p>
          </div>
        </div>
        <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
          <Alert onClose={this.handleClose} severity="success">
          {this.state.item && this.state.item.get("Name")} a bien été ajouté à votre panier
          </Alert>
        </Snackbar>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    basketList: state.basketList
  };
}
export default connect(mapStateToProps)(withRouter(ItemPage));
// export default withRouter(ItemPage);
