export const resolveQueryParam = (router, keys) => {
  const query = new URLSearchParams(router.location.search)
  let search = {}

  for(let i = 0, len = keys.length; i < len; ++i) {
      search[keys[i]] = query.get(keys[i])
  }

  return search
}

export const parseFilter = (filter) => {
  const filters = filter.split('|')
  //product_cat[209]|ambiances[206-201]
  const toRet =  {}
  for(let i = 0, len = filters.length; i < len; ++i) {
    const filterStr = filters[i]
    const splitedFilterStr = filterStr.split('[')
    const name = splitedFilterStr[0]
    const values = splitedFilterStr[1].replace(']', '').split('-').map(v => parseInt(v, 10))

    toRet[name] = values
  }

  return toRet
}

export const QUERYMAPPING_AMBIANCE = {
  'noir': 204,
  'bleu': 205,
  'denim': 205,
  'blanc': 206,
  'lin': 206,
  'jaune': 202,
  'vert': 203,
  'sauge': 203,
  'rouge': 201,
  'terracota': 201,
  'anthracite' : 204,
  'naturel' : 206,
  'safran' : 202,
  'sauge' : 203,
  'Terracotta' : 201,
}



export const QUERYMAPPING_CATEGORY = {
  'boho': 209,
  'fashion rétro': 217,
  'fresh': 207,
  'grafik ethnik': 211,
  'kokoon': 210,
  'slow déco': 212
}